<template>
  <div>
    <div style="background-color: #FFFFFF;" >
      <img :src="data.headLineInfoList.coverImage" class="newsImg" />
      <div class="newsBody">
        <div class="newsTitle" >
          <text style="font-size:0.42667rem;">{{ data.headLineInfoList.title }}</text>
        </div>
        <div class="newsTool" style="font-weight: normal;">
          <text>{{ data.headLineInfoList.createTime }}</text>
          <div class="preview">
            <img src="../assets/image/预览.png" class="newsIcon">
            <text>{{ data.headLineInfoList.readNum }}</text>
          </div>
        </div>
        <div id="container"
             style="width:100%;padding:20px;">
        </div>
<!--        <div class="">-->
<!--          <div style="margin-left: 380rpx;margin-top: 60rpx;" v-if="data.headLineInfoList.isRead">-->
<!--            <div style="display: flex;align-items: center;" @click="lookImg2(data.headLineInfoList.headlineRead.signatureUrl)">-->
<!--              <text style="margin-right: 20rpx;">签名</text>-->
<!--              <img :src="data.headLineInfoList.headlineRead.signatureUrl" class="canvasImg">-->
<!--            </div>-->
<!--            <div style="display: flex;align-items: baseline;margin-top: 20rpx;">-->
<!--              <text style="margin-right: 20rpx;">日期</text>-->
<!--              <text>{{ (data.headLineInfoList.headlineRead.createTime || '').slice(0, 10) }}</text>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
      </div>
<!--      <div class="btnBox" v-if="!data.headLineInfoList.isRead">-->
<!--        <button class="bigBtn blue" v-if="signature" @tap="canvas">立即签名</button>-->
<!--        &lt;!&ndash; <button class="bigBtn blue" v-else @tap="confirm">确认</button> &ndash;&gt;-->
<!--      </div>-->
<!--      <div class="canvasBtn">-->
<!--        <button class="smallBtn white" @tap="clearCanvas">清除重签</button>-->
<!--        <button class="smallBtn dis" v-if="!isEnd" @tap="showToast">完成签名</button>-->
<!--        <button class="smallBtn blue" v-else @tap="finishCanvas">完成签名</button>-->
<!--      </div>-->
      <div style="height: 150rpx;">
      </div>
    </div>
  </div>
</template>

<script>
import {onMounted, reactive} from "vue";
import {headLineInfoGet, headLineReadNumGet} from "@/api/headLine";
import {useRoute} from "vue-router";

export default {
  setup(){
    const onClickTab = () => {
      getHeadLineInfoList()
    };
    const data = reactive({
      headLineInfoList:[],
    })
    onMounted(()=>{
      let data1 = {
        id:useRoute().query.id,
      }
      headLineReadNum(data1)
    })
    const headLineReadNum = (data1) => {
      headLineReadNumGet(data1).then(res=>{
        console.log(res)
        getHeadLineInfoList(data1)
      })
    }

    const getHeadLineInfoList = (data1) => {
      headLineInfoGet(data1).then(res=>{
        let content = res.data.content;
        let container = document.getElementById("container"); // 获取容器元素
        container.innerHTML = content;
        console.log(res.data)
        data.headLineInfoList = res.data
        console.log("1212121")
        console.log(data.headLineInfoList)
      })
    }

    return {
      data,
      onClickTab,
      headLineReadNum,
      getHeadLineInfoList,

    };
  }
}
</script>

<style lang="scss" scoped>
.styleBottom {
  //margin-bottom: 200px;
  #container {
    margin-bottom: 200px;
  }
}
.canvasImg {
  width: 190px;
  height: 100px;
}
.newsImg {
  width: 100vw;
  min-height: 30vh;
}

.newsBody {
  padding: 30px;

  font-size: 0.42667rem;
  font-weight: 700;
}

.newsTitle {
  //font-size: 36px;
  //font-weight: 700;

  font-weight: 700;
  margin-bottom: .37333rem;
}

.newsTool {
  width: 100%;
  color: #999999;
  font-size: 0.42667rem;
  margin: 20px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.preview {
  display: flex;
  align-items: center;
}

.newsIcon {
  width: 30px;
  height: 30px;
  margin-right: 20px;
}

.btnBox {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100vw;
  height: 120px;
  padding-bottom: 30px;
  background-color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bigBtn {
  width: 640px;
  height: 90px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mycanvas {
  width: 690px;
  height: 400px;
  margin-top: 80px;
  margin-left: 30px;
  border-radius: 5px;
  background-color: #F5F5F5;
  position: relative;
}

.canvasText {
  width: 240px;
  text-align: center;
  color: #999999;
  font-size: 0.42667rem;
  position: absolute;
  top: 180px;
  left: 245px;
  white-space: nowrap;
}

.canvasBtn {
  width: 100vw;
  height: 150px;
  padding-bottom: 30px;
  background-color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.smallBtn {
  width: 330px;
  height: 90px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: #1890ff 1px solid;
}

.white {
  color: #1890ff;
  background-color: #FFFFFF;
}

.blue {
  color: #FFFFFF;
  background-color: #1890ff;
}

.dis {
  color: rgba(255, 255, 255, 0.9);
  border: rgba(24, 144, 255, 0.2);
  background-color: rgba(24, 144, 255, 0.2);
}
</style>
