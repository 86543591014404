import {createRouter, createWebHashHistory} from 'vue-router'
// 首页
import home from '../views/home.vue'
// 未命名
import sparkList from '../views/sparkList.vue'
//共同体列表
import community from "../views/community.vue";
// 活动 列表
import eventsList from '../views/eventsList.vue'
// 活动 详情
import eventDetails from '../views/eventDetails.vue'
// 个人中心
import IndividualCenter from '../views/IndividualCenter.vue'
// 活动 报名
import signUpFome from '../views/signUpFome.vue'
// 我的 积分
import myIntegral from '../views/myIntegral.vue'
//头条
import headLine from '../views/headLine.vue'
//头条详情
import headLineInfo from '../views/headLineInfo.vue'
// 积分 明细
import integralDetail from '../views/integralDetail.vue'
//积分商城
import integralShop from '../views/integralShop.vue'
// 兑换 记录
import exchangeRecord from '../views/exchangeRecord.vue'
// 兑换 详情
import exchangeParticulars from '../views/exchangeParticulars.vue'
// 商品 详情
import productDetails from '../views/productDetails.vue'
// 我的 活动 列表
import myActivity from '../views/myActivity.vue'
// 活动 核销码
import checkOffCode from '../views/checkOffCode.vue'
// 个人中心 设置
import settings from '../views/settings.vue'
//登录
import login from '../views/login.vue'
//注册
import zhuce from '../views/zhuce.vue'
// 底部 菜单
import tab from '../components/tab.vue'
// 外部链接页面
import externalConnection from '../views/externalConnection.vue'
import externalConnection2 from '../views/externalConnection2.vue'



const routes = [
// 首页
    {
        path: '/',
        name: 'home',
        component: home
    },
// 未命名
    {
        path: '/sparkList',
        name: 'sparkList',
        component: sparkList
    },
// 共同体 列表
    {
        path: '/community',
        name: 'community',
        component: community
    },
// 活动 列表
    {
        path: '/eventsList',
        name: 'eventsList',
        component: eventsList
    },
// 活动 详情
    {
        path: '/eventDetails',
        name: 'eventDetails',
        component: eventDetails
    },
// 个人中心
    {
        path: '/IndividualCenter',
        name: 'IndividualCenter',
        component: IndividualCenter
    },
// 活动 报名
    {
        path: '/signUpFome',
        name: 'signUpFome',
        component: signUpFome
    },
// 我的 积分
    {
        path: '/myIntegral',
        name: 'myIntegral',
        component: myIntegral
    },
// 积分 明细
    {
        path: '/integralDetail',
        name: 'integralDetail',
        component: integralDetail
    },
//积分商城
    {
        path: '/integralShop',
        name: 'integralShop',
        component: integralShop
    },
// 兑换 记录
    {
        path: '/exchangeRecord',
        name: 'exchangeRecord',
        component: exchangeRecord
    },
// 兑换 详情
    {
        path: '/exchangeParticulars',
        name: 'exchangeParticulars',
        component: exchangeParticulars
    },
// 商品 详情
    {
        path: '/productDetails',
        name: 'productDetails',
        component: productDetails
    },
// 我的 活动 列表
    {
        path: '/myActivity',
        name: 'myActivity',
        component: myActivity
    },
    // 我的 头条
    {
        path: '/headLine',
        name: 'headLine',
        component: headLine
    },
    // 我的 头条详情
    {
        path: '/headLineInfo',
        name: 'headLineInfo',
        component: headLineInfo
    },
// 活动 核销码
    {
        path: '/checkOffCode',
        name: 'checkOffCode',
        component: checkOffCode
    },
// 个人中心 设置
    {
        path: '/settings',
        name: 'settings',
        component: settings
    },
//登录
    {
        path: '/login',
        name: 'login',
        component: login
    },
//注册
    {
        path: '/zhuce',
        name: 'zhuce',
        component: zhuce
    },
// 底部 菜单
    {
        path: '/tab',
        name: 'tab',
        component: tab
    },
    // 外部链接页面
    {
        path: '/externalConnection',
        name: 'externalConnection',
        component: externalConnection
    },
    // 外部链接页面
    {
        path: '/externalConnection2',
        name: 'externalConnection2',
        component: externalConnection2
    },
    // {
    //   path: '/about',
    //   name: 'about',
    //   // route level code-splitting
    //   // this generates a separate chunk (about.[hash].js) for this route
    //   // which is lazy-loaded when the route is visited.
    //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
    // }
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

export default router
