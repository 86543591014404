<script>
import {showConfirmDialog, showToast} from "vant";
import tab from "../components/tab.vue";
import {acvitityListGet} from "@/api";
import router from "@/router";

export default {
  props: {
    // jumpType: {
    //   type: Number,
    // },
  },
  components: {
    tab,
  },
  data() {

    return {
      attr: [
        {
          jumpUrl: "/",
          imgUrl: "http://jjh-jjh.oss-cn-hangzhou.aliyuncs.com/2023/f2fb0d4061ea2f9d77e50b89ba609d2.png",
          id: 1,
          name: "共同体",
        },
        {
          jumpUrl: "https://m.wefu.net/app/index.php?i=2&c=site&a=site&cid=1",
          imgUrl: "http://jjh-jjh.oss-cn-hangzhou.aliyuncs.com/2023/b2565694e06b52108cec47b46d4bbbd.png",
          id: 2,
          name: "区域项目",
        },
        {
          jumpUrl: "https://m.wefu.net/app/index.php?i=2&c=site&a=site&do=detail&id=16",
          imgUrl: "http://jjh-jjh.oss-cn-hangzhou.aliyuncs.com/2023/6bf6ecff276f6f3d2d3ecbf66f5e8f1.png",
          id: 3,
          name: "区域轮值",
        },
        {
          jumpUrl: "/",
          imgUrl: "http://jjh-jjh.oss-cn-hangzhou.aliyuncs.com/2023/40e20e1138c918e4f44cee2e895ea9b.png",
          id: 4,
          name: "区域动态",
        },
        {
          jumpUrl: "/",
          imgUrl: "http://jjh-jjh.oss-cn-hangzhou.aliyuncs.com/2023/9ca92f158ccf689d1175fcbb8561eea.png",
          id: 5,
          name: "活动报名",
        },
        {
          jumpUrl: "https://m.wefu.net/app/index.php?i=2&c=entry&m=navlange_reserve_place&do=list",
          imgUrl: "http://jjh-jjh.oss-cn-hangzhou.aliyuncs.com/2023/6a8ddee3ca6f630fc073d8e9d57338c.png",
          id: 6,
          name: "场馆预约",
        },
        {
          jumpUrl: "https://m.wefu.net/app/index.php?i=2&c=entry&sx=1&do=wishes&m=cn_wishes",
          imgUrl: "http://jjh-jjh.oss-cn-hangzhou.aliyuncs.com/2023/663699096e5d4e22fcbd0e93d5b18c3.png",
          id: 7,
          name: "微心愿",
        },
        {
          jumpUrl: "http://xxlgcwx.sipac.gov.cn/score/index",
          imgUrl: "http://jjh-jjh.oss-cn-hangzhou.aliyuncs.com/2023/c40f82e9105530b0045c29571702156.png",
          id: 8,
          name: "星火学院",
        },
      ],
      aixinAttar: [
        {
          imgUrl:
              "http://xxlgcwx.sipac.gov.cn/image/202207/1657524372236326605.png",
          name: "“护环境 优水质 促民生”金鸡湖步道徒步及观影活动",
          id: 1,
        },
        {
          imgUrl:
              "http://xxlgcwx.sipac.gov.cn/image/202206/1654513636906793858.png",
          name: "礼赞志愿  感谢有你",
          id: 2,
        },
        {
          imgUrl:
              "http://xxlgcwx.sipac.gov.cn/image/202206/1654509961205213184.jpg",
          name: "接种新冠疫苗  建立防护屏障",
          id: 3,
        },
        {
          imgUrl:
              "http://xxlgcwx.sipac.gov.cn/image/202206/1654507386596118390.png",
          name: "爱心助考",
          id: 4,
        },
        {
          imgUrl:
              "http://xxlgcwx.sipac.gov.cn/image/202112/1640311307097394217.png",
          name: "“双减后，助力宝贝新成长”",
          id: 5,
        },
      ],
      nryxAttr: [
        {
          imgUrl:
              "http://jjh-jjh.oss-cn-hangzhou.aliyuncs.com/2023/1682388505966129292.jpg",
          name: "一场“福气满满”的双向奔赴，我们的节日和...",
          id: 1111,
          items: "2023-04-25 14:45",
          jumpUrl: 'http://xxlgcwx.sipac.gov.cn/top-news/detail?id=6447770c4536194e2f5b9a38'
        },
        {
          imgUrl:
              "http://jjh-jjh.oss-cn-hangzhou.aliyuncs.com/2023/1682414002608476661.jpg",
          name: "金楫善治 | “急难愁盼”怎么解？金鸡湖...",
          id: 1111,
          items: "2023-04-25 17:14",
          jumpUrl: 'http://xxlgcwx.sipac.gov.cn/top-news/detail?id=644799e34536194e2f5b9a84'
        },
        {
          imgUrl:
              "http://jjh-jjh.oss-cn-hangzhou.aliyuncs.com/2023/1686130659863985224.png",
          name: "金楫微光·聚网成炬|欧典社区：由“乱”到...",
          id: 1111,
          items: "2023-06-07 17:37",
          jumpUrl: 'http://xxlgcwx.sipac.gov.cn/top-news/detail?id=64804fe64536194e2f5c0a08'
        },
        {
          imgUrl:
              "http://jjh-jjh.oss-cn-hangzhou.aliyuncs.com/2023/1690166430493777263.png",
          name: "金楫微光·聚网成炬|澳韵社区：党建引领解...",
          id: 1111,
          items: "2023-07-24 10:41",
          jumpUrl: 'http://xxlgcwx.sipac.gov.cn/top-news/detail?id=64bde4ec4536194e2f5c82c4'
        }, {
          imgUrl:
              "http://jjh-jjh.oss-cn-hangzhou.aliyuncs.com/2023/1690166605980520891.png",
          name: "金楫微光·聚网成炬|高尔夫社区：如何从无...",
          id: 1111,
          items: "2023-07-24 10:45",
          jumpUrl: 'http://xxlgcwx.sipac.gov.cn/top-news/detail?id=64bde5ba4536194e2f5c82cc'
        }, {
          imgUrl:
              "http://jjh-jjh.oss-cn-hangzhou.aliyuncs.com/2023/1690166811386119060.png",
          name: "《新华日报》聚焦 | “邻聚里”，释放更...",
          id: 1111,
          items: "2023-07-24 10:47",
          jumpUrl: 'http://xxlgcwx.sipac.gov.cn/top-news/detail?id=64bde6534536194e2f5c82d2'
        }, {
          imgUrl:
              "http://jjh-jjh.oss-cn-hangzhou.aliyuncs.com/2023/1690167003630158142%20(1).png",
          name: "金楫微光·聚网成炬|湖左岸社区：众筹金点...",
          id: 1111,
          items: "2023-07-24 10:51",
          jumpUrl: 'http://xxlgcwx.sipac.gov.cn/top-news/detail?id=64bde71b4536194e2f5c82da'
        }, {
          imgUrl:
              "http://jjh-jjh.oss-cn-hangzhou.aliyuncs.com/2023/1700738515995639187.jpg",
          name: "蝶变亮相！这个党群服务中心等你来“打卡”...",
          id: 1111,
          items: "2023-11-23 19:22",
          jumpUrl: 'http://xxlgcwx.sipac.gov.cn/top-news/detail?id=655f36004536194e2f5dbeda'
        }, {
          imgUrl:
              "http://jjh-jjh.oss-cn-hangzhou.aliyuncs.com/2023/1700738803711050957.jpg",
          name: "笃行 维新 创优！金鸡湖街道召开“众引力...",
          id: 1111,
          items: "2023-11-23 19:29",
          jumpUrl: 'http://xxlgcwx.sipac.gov.cn/top-news/detail?id=655f379f4536194e2f5dbee2'
        }, {
          imgUrl:
              "http://jjh-jjh.oss-cn-hangzhou.aliyuncs.com/2023/1700739046668343219.jpg",
          name: "《苏州日报》聚焦 | 探索为民服务“金鸡...",
          id: 1111,
          items: "2023-11-23 19:31",
          jumpUrl: 'http://xxlgcwx.sipac.gov.cn/top-news/detail?id=655f38114536194e2f5dbeea'
        },
      ],
      scrollPosition: 0,
      isLoading: false,
      lastLoadedItemCount: 0,
      items: [], // 加载的数据
    };
  },
  computed: {
    windowHeight() {
      return window.innerHeight + "px";
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    this.loadMoreData(); // 初始化加载数据
    document.title = "首页";
    this.acvitityListGetlIST();
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {

    // 向父级传输数值，父级根据数值来判断显示哪一个组件
    // jumpType(data){
    //   console.log(data);
    //   this.$emit('jumType',data.jumpTypeText)
    // },
    // 点击事件区域
    /**
     * data是事件源传递过来的数据，
     * data.jumpUrl判断这个非"/"则直接通过window.location.href跳转传递过来的路径
     */


    handleScroll(event) {
      const scrollContainer = event.target;
      const scrollHeight = scrollContainer.scrollHeight;
      const scrollTop = scrollContainer.scrollTop;
      const clientHeight = scrollContainer.clientHeight;

      // 判断是否到达底部，触发上拉加载逻辑
      if (scrollTop + clientHeight >= scrollHeight) {
        this.loadMoreData();
      }

      // 判断是否到达顶部，触发下拉刷新逻辑
      if (scrollTop === 0) {
        this.refreshData();
      }
    },
    loadMoreData() {
      // 处理上拉加载逻辑
      console.log("1");
      this.isLoading = true;

      // 模拟异步操作
      // setTimeout(() => {
      //   // 加载完成后添加新数据
      //   const newData = [
      //     {
      //       imgUrl:
      //         "http://xxlgcwx.sipac.gov.cn/image/202307/1690167003630158142.png",
      //       name: "11111111111111111111",
      //       id: 1,
      //       items: "1111111111111111",
      //     },
      //   ];
      //   this.nryxAttr = [...this.nryxAttr, ...newData];
      //   this.isLoading = false;
      // }, 2000);
    },
    refreshData() {
      // 处理下拉刷新逻辑
      console.log("2");
    },
    acvitityListGetlIST() {
      acvitityListGet().then(res => {
        console.log(res.data)
        this.aixinAttar = res.data
      })
    }
  },
  setup(_, context) {
    const chakanxiangqing = (e) => {
      console.log(e)
      console.log(e.item)
      if (e.jumpStyle === '活动') {
        router.push({name: 'eventDetails', query: {id: e.item.id}});
      }
    }
    const jump = (e) => {
      console.log(e)
      if (e.item) {
        if (e.item.name === "区域组织") {
          window.location.href = e.item.jumpUrl;
          // showToast("区域组织未开启，尽情期待");
          return
        }
        if(e.item.name === "区域动态"){
          router.push({name: 'headLine'});
          return
        }
        if(e.item.name === "共同体"){
          router.push({name: 'community'});
          return
        }
        if (e.item.name === "活动报名" || e.item.name === "星火学院") {
          console.log("活动报名")
          router.push({name: 'eventsList'});
          return
        } else if (e.item.name === "我的积分") {
          if (localStorage.getItem('token')) {
            router.push({name: 'myIntegral'});
          } else {
            showConfirmDialog({
              title: '温馨提示',
              message: '此项操作需要登录才可以继续哦，是否前往登录。',
            })
                .then(() => {
                  router.push({name: 'login', query: {type: '活动'}});
                })
                .catch(() => {
                });
          }
          // router.push({ name: 'myIntegral', query: { id: e.item.id } });
          return
        } else {
          window.location.href = e.item.jumpUrl;
        }
      }
      if (e.jumpStyle === "内部跳转") {
        router.push(e.jumpPush);
      }
      if(e.jumpStyle === "头条"){
        router.push(e.jumpPush);
      }
      // if (e.jumpStyle === "活动"){
      //
      // }

    }
    return {
      chakanxiangqing,
      jump,
    }
  }

};
</script>

<template>
  <!-- 首页主体盒子 -->
  <div
      :style="{ height: windowHeight, overflow: 'auto' }"
      class="box scroll-container"
      @scroll="handleScroll"
  >
    <!-- 标题 -->
    <div class="title"></div>
    <!-- 巨幕 -->
    <div class="carousel">
      <img alt="" src="../assets/image/banner-3.7b1fc76c.gif"/>
    </div>
    <!-- 菜单 -->
    <div class="menu-box">
      <div v-for="item in attr" :key="item.id" class="menu">
        <div class="menu-img" @click="jump({ item: item})">
          <img :src="item.imgUrl" alt=""/>
          <!-- <img src="../assets/image/1638.png" alt="" /> -->
        </div>
        <div class="menu-text">{{ item.name }}</div>
      </div>
    </div>
    <!-- 分割线 -->
    <div class="ssx"></div>
    <!-- 标题 -->
    <div class="title2">
      <div class="title21">最新活动·ACTIVITY</div>
      <div
          class="title22"
          @click="jump({ jumpStyle: '内部跳转', jumpPush: '/eventsList' })"
      >
        查看更多
      </div>
    </div>
    <!-- 最新活动轮播 -->
    <div class="carousel2">
      <van-swipe :loop="true" :width="300">
        <van-swipe-item
            v-for="item in aixinAttar"
            :key="item.id"
            class="contentStyle"
        >
          <div class="carousel-item-content" @click="chakanxiangqing({jumpStyle: '活动',item:item})">
            <img :src="item.thumbanil" alt=""/>
            <div class="contentStyleTest">{{ item.acvitityName }}</div>
          </div>
        </van-swipe-item>
      </van-swipe>
    </div>
    <!-- 标题 -->
    <div class="title2">
      <div class="title21">内容优选·CONTENT</div>
            <div class="title22" @click="jump({ jumpStyle: '头条', jumpPush: '/headLine' })">查看更多</div>

    </div>
    <!-- 内容优选列表 -->
    <div class="conteneListBox">
      <div v-for="item in nryxAttr" :key="item.id" class="contentList" @click="jump({jumpStyle:'内容优选',item:item})">
        <div class="contentImg">
          <img :src="item.imgUrl" alt=""/>
        </div>
        <div class="contentTestBox">
          <p class="text1">{{ item.name }}</p>
          <p class="text2">{{ item.items }}</p>
        </div>
      </div>
    </div>
    <div v-if="isLoading" class="loading-indicator">正在加载...</div>
  </div>
  <tab></tab>
</template>
<style lang="scss" scoped>
img {
  display: block;
  width: 100%;
  height: 100%;
}

.box {
  width: 750px;
  overflow: hidden;
  overflow: auto;
  //padding-top: 20px;
  .title {
    background-color: rgba(255, 255, 255, 1);
    color: rgba(16, 16, 16, 1);
    font-size: 0.6rem;
    text-align: center;
    font-family: Roboto;
    //margin-bottom: 20px;
    //padding-top: 20px;
  }

  .carousel {
    width: 750px;
    height: 320px;
    // background: red;
    border-radius: 4 * 2px;
    margin: 0 auto;
    margin-bottom: 30px;
    box-shadow: 0 0 10px #ccc;
    overflow: hidden;
  }

  .menu-box {
    width: 348 * 2px;
    // border: 1px solid red;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;

    .menu {
      margin-bottom: 10 * 2px;
      width: 25%;

      .menu-img {
        width: 48 * 2px;
        height: 48 * 2px;
        // background: red;
        border-radius: 50%;
        margin: 0 auto;
        margin-bottom: 10 * 2px;
      }

      .menu-text {
        width: 100%;
        font-size: 0.4rem;
        text-align: center;
      }
    }
  }

  .ssx {
    width: 348 * 2px;
    height: 1px;
    border-bottom: 1px solid #f8f8f8;
    margin: 0 auto;
  }

  .title2 {
    width: 346 * 2px;
    margin-bottom: 10 * 2px;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    padding-top: 10 * 2px;

    .title21 {
      font-size: 0.5rem;
      font-weight: 600;
    }

    .title22 {
      font-size: 0.35rem;
      border: 1px solid #7d7e80;
      color: #7d7e80;
      border-radius: 10 * 3px;
      padding-left: 20px;
      padding-right: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .carousel2 {
    width: 348 * 2px;
    margin: 20px auto;

    .contentStyle {
      width: 290 * 2px;
      height: 169 * 2px;
      text-align: center;
      // background: red;
      border-radius: 8 * 2px;
      overflow: hidden;
      position: relative;

      .carousel-item-content {
        padding-right: 11 * 2px;

        img {
          border-radius: 0 8 * 2px 0 0;
        }

        .contentStyleTest {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 11 * 2px;
          background: rgba($color: #232323, $alpha: 0.5);
          font-size: 0.4rem;
          padding: 10px;
          color: #fff;
          border-radius: 0 0 8 * 2px 0;
        }
      }
    }
  }

  .conteneListBox {
    width: 343 * 2px;
    margin: 0 auto;

    .contentList {
      box-shadow: 0 0 7px #ccc;
      margin-top: 10 * 2px;
      margin-bottom: 30px;
      border-radius: 10px 10px 0 0;
      overflow: hidden;

      .contentImg {
        width: 343 * 2px;
        height: 157 * 2px;
        // background: red;
      }

      .contentTestBox {
        padding-left: 10px;
        padding-right: 10px;

        .text1 {
          font-size: 0.4rem;
          margin-top: 10 * 2px;
          margin-bottom: 10 * 2px;
        }

        .text2 {
          color: #999999;
          font-size: 0.3rem;
          padding-bottom: 10 * 2px;
        }
      }
    }
  }

  .loading-indicator {
    text-align: center;
    font-size: 0.4rem;
    color: #232323;
    padding-bottom: 20px;
  }
}
</style>
