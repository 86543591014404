<template>
  <div>
    <div class="jiFenShangChengTiaoTi">
      <div class="leftBackground"></div>
      <div class="leftText">积分商城</div>
    </div>
    <div class="shangPinListBox">
      <div class="shangPinList" v-for="(item,index) in data.shopGet" :key="index" @click="code({item:item})">
        <div class="img"><img :src="item.goodsImage" alt=""/></div>
        <div class="text">{{item.goodsName}}</div>
        <div class="jiFenBox">
          <div class="left"><span>{{item.exchangeIntegral}}</span> 积分</div>
          <div class="right">数量：{{item.goodsResidue}}</div>
        </div>
        <div class="bottom" v-if="item.exchangeIntegral <= data.availableIntegral">
          立即兑换
        </div>
        <div class="bottom" style="background-color: #F4C6CB" v-else-if="item.exchangeIntegral > data.availableIntegral">积分不足</div>
      </div>
    </div>
  </div>
</template>

<script>
import {pioneerShopGet} from "@/api/integralDetail";
import {onMounted, reactive} from "vue";
import router from "@/router";
import {pioneerGet} from "@/api/myIntegral";

export default {
  setup(){
    const data = reactive({
      availableIntegral: null,
      rules:null,
      rulesAttr:[],
      totalIntegral:null,
    })
    onMounted(() => {
      pioneerShop();
      integralInfo()
    })
    const pioneerShop = () =>{
      const type = localStorage.getItem('type');
      if(type==='1'){
        pioneerShopGet({ exchangeObjectValue: '2' }).then(res=>{
          data.shopGet =  res.data.records
        })
      }else{
        pioneerShopGet({ exchangeObjectValue: '3' }).then(res=>{
          data.shopGet =  res.data.records
        })
      }
    }
    const code = (e) => {
      console.log("aaaa", e.item.id)
      router.push({name: 'productDetails', query: {id: e.item.id}})
    }
    const integralInfo = () => {
      pioneerGet().then(res => {
        if (res.code === 200) {
          data.totalIntegral = res.data.statistics.totalIntegral ? res.data.statistics.totalIntegral : 0
          data.availableIntegral = res.data.statistics.availableIntegral ? res.data.statistics.availableIntegral : 0

        }
      })
    }
    return {
      data,
      code,
    }
  }
}
</script>

<style lang="scss" scoped>
.jiFenShangChengTiaoTi {
  display: flex;
  align-items: center;
  margin-bottom: 22*2px;
  position: sticky;
  top: 0;
  z-index: 1000;
  background: #fff;
  padding-top: 10px;
  padding-bottom: 10px;

  .leftBackground {
    width: 6px;
    height: 40px;
    background: red;
    margin-left: 26px;
    margin-right: 16px;
  }

  .leftText {
    font-size: 16*2px;
    color: #333;
  }
}
.shangPinListBox {
  width: 750px;
  padding-left: 22px;
  padding-right: 22px;
  display: flex;
  flex-wrap: wrap;

  .shangPinList {
    width: 171*2px;
    height: 220*2px;
    background-color: rgba(255, 255, 255, 1);
    color: rgba(16, 16, 16, 1);
    box-shadow: 1px 6px 12px 0px rgba(187, 187, 187, 20);
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;

    .img {
      width: 148*2px;
      height: 99*2px;
      border-radius: 4*2px;
      overflow: hidden;
      margin-bottom: 20px;
    }

    .text {
      font-size: 14*2px;
      color: #333;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 8*2px;
    }

    .jiFenBox {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10*2px;

      .left {
        color: #ff6637;
        font-size: 14*2px;

        span {
          font-weight: bold;
          font-size: 18*2px;
        }
      }

      .right {
        color: #999;
        font-size: 12*2px;
      }
    }

    .bottom {
      width: 143*2px;
      height: 30*2px;
      background: #ee0a24;
      border-radius: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-size: 14*2px;
    }

    .bottom2 {
      width: 143*2px;
      height: 30*2px;
      background: #FCCED3;
      border-radius: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-size: 14*2px;
    }
  }

  .shangPinList:nth-child(2n) {
    margin-left: 20px;
  }
}
</style>
