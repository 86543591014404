<template>
  <div style="background:#f8f8f8;margin-bottom: 150px" @scroll="handleScroll">
    <!-- Vant的TAB切换标签组件 -->

    <van-tabs
        v-model:active="activeName"
        color="#EE0A24"
        line-height="2px"
        @click-tab="onClickTab"
    >
      <!--       title是TAB标签名，根据name来切换展示相应的对面 -->
      <van-tab
          v-for="(item,index) in data.tabAttr"
          :key="index"
          :name="item.name"
          :title="item.title"
      >
        <!-- 内容列表信息 -->
        <div v-for="(item2,index) in data.eventsAttr" :key="index"
             :class="['bmzList',
         item2.acvitityStatus === 4 ? 'bmzList3' : 'bmzList2'
          ]">
<!--          item2.acvitityStatus === 4-->
          <div class="ledtImg">
            <img :src="item2.thumbanil" alt=""/>
          </div>
          <div class="topTexts" @click="jump({jumpStyle:'详情',item:item2})">
            <div class="topText1">{{ item2.acvitityName }}</div>
            <div class="topText2">活动时间：{{ item2.acvitityStartTime.slice(0, 10) }} ~
              {{ item2.acvitityEndTime.slice(0, 10) }}
            </div>
            <div class="topText3">已报名：{{ item2.applyNum }} / {{ item2.places }}</div>
            <div class="topText4">
              <div style="text-align: left">
                <div class="topText41" style="margin-top: 10px">{{item2.applyTiem}}</div>
                <div class="topText41">{{item2.applyTiem2}}</div>
              </div>
              <div v-show="item2.acvitityStatus === 4 || item2.acvitityStatus === 5 || item2.acvitityStatus  === 6"
                   class="zt2">
                <img v-show="item2.acvitityStatus === 5"
                     alt="" src="https://jjh-jjh.oss-cn-hangzhou.aliyuncs.com/2023/%E5%9B%BE%E6%A0%87/bmjz.png">
                <img v-show="item2.acvitityStatus === 6"
                     alt="" src="https://jjh-jjh.oss-cn-hangzhou.aliyuncs.com/2023/%E5%9B%BE%E6%A0%87/meym.png">
                <img v-show="item2.acvitityStatus === 4"
                     alt="" src="https://jjh-jjh.oss-cn-hangzhou.aliyuncs.com/2023/%E5%9B%BE%E6%A0%87/yjs.jpg">
              </div>
              <div v-show="item2.acvitityStatus === 1 || item2.acvitityStatus === 2 || item2.acvitityStatus === 3"
                   :class="
                    item2.acvitityStatus=== 1 ? 'anniuyangshixiugai1':
                    item2.acvitityStatus === 2 ? 'anniuyangshixiugai2':
                    item2.acvitityStatus === 3 ? 'anniuyangshixiugai3':
                    '' "
                   class="anniuyangshixiugai"
              >
<!--                <van-icon name="success"/>-->
                {{
                  item2.acvitityStatus === 1 ? '立即报名' :
                      item2.acvitityStatus === 2 ? '已报名' :
                          item2.acvitityStatus === 3 ? '即将开始' :
                              item2.acvitityStatus === 4 ? '已结束' :
                                  item2.acvitityStatus === 5 ? '已截止' :
                                      item2.acvitityStatus === 6 ? '名额已满' :
                                          ''
                }}
              </div>
            </div>
          </div>
        </div>
        <van-empty v-show="data.eventsAttr.length < 1" description="暂无数据"
                   style="position: fixed; top: 40px; left: 0;right: 0;bottom: 100px;margin: auto"/>
      </van-tab>
    </van-tabs>
  </div>

  <tab></tab>
</template>

<script>
import {ref, onMounted, reactive, onBeforeUnmount, onUnmounted} from "vue";
import {acvitityListGet} from "@/api";
import {useRouter} from 'vue-router';
import Tab from "@/components/tab.vue";
import router from "@/router";
import {showConfirmDialog} from "vant";

export default {
  components: {Tab},
  setup(_, context) {

    const router = useRouter();
    const data = reactive({
      liebiaoListType: null,
      eventsAttr: [], // 活动列表attr
      tabAttr: [
        {
          title: "全部",
          name: "1",
        },
        {
          title: "报名中",
          name: "2",
        },
        {
          title: "即将报名",
          name: "3",
        },
        {
          title: "已结束",
          name: "4",
        },

      ],
      dataAttr: [
        {
          imgUrl: '/src/assets//image/233.jpg',
          name1: '企业安全宣传日报名活动',
          name2: '活动时间：2023-01-01 ～ 2023-01-01',
          name3: '已报名：0/30',
          name4: '距离报名开始 08:54:45',
          name5: '即将报名',
          id: 1
        },
        {
          imgUrl: '/src/assets//image/233.jpg',
          name1: '企业安全宣传日报名活动',
          name2: '活动时间：2023-01-01 ～ 2023-01-01',
          name3: '已报名：0/30',
          name4: '距离报名开始 08:54:45',
          name5: '即将报名',
          id: 2
        },
      ],
      fontColor: 'topText42',
      page: 1,
      dtCss: '',
      dsq:'',
    })
    const activeName = ref("a");
    const dataAttr = ref([]);

    // 在组件挂载后发送请求，并将返回的数据赋值给 dataAttr
    onMounted((type) => {
      document.title = "活动列表";
      // 活动 - 列表
      liebiao();
    });
    const liebiao = () => {
      let a = {
        acvitityStatus: data.liebiaoListType,
        pageNo: data.page,
        pageSize: 99999
      }
      acvitityListGet(a).then(res => {
        console.log(res)
        for (const resKey in res.data) {
          const time = Date.now();
          const acvitityEnd = Date.parse(res.data[resKey].acvitityEndTime);
          const endTime = Date.parse(res.data[resKey].applyEndTime);
          const start = Date.parse(res.data[resKey].applyStartTime) - Date.now(); // 距离报名开始
          const end = Date.parse(res.data[resKey].applyEndTime) - Date.now(); // 距结束报名时间
          if (res.data[resKey].acvitityStatus === 1 ||res.data[resKey].acvitityStatus === 2 ){
            // 将时间戳转换为秒数
            const totalSecond = Math.floor(end / 1000);
            // 计算天数、小时、分钟和秒数
            const days = Math.floor(totalSecond / (24 * 60 * 60));
            const hours = Math.floor((totalSecond % (24 * 60 * 60)) / (60 * 60));
            const minutes = Math.floor((totalSecond % (60 * 60)) / 60);
            const seconds = totalSecond % 60;
            console.log(`天数: ${days}`);
            console.log(`小时: ${hours}`);
            console.log(`分钟: ${minutes}`);
            console.log(`秒数: ${seconds}`);
            res.data[resKey].applyTiem = `距离报名结束：`;
            res.data[resKey].applyTiem2=`${days}天${hours}小时${minutes}分${seconds}秒`;
          }
          if (res.data[resKey].acvitityStatus === 3){
            // 将时间戳转换为秒数
            const totalSeconds = Math.floor(start / 1000);
            // 计算天数、小时、分钟和秒数
            const days = Math.floor(totalSeconds / (24 * 60 * 60));
            const hours = Math.floor((totalSeconds % (24 * 60 * 60)) / (60 * 60));
            const minutes = Math.floor((totalSeconds % (60 * 60)) / 60);
            const seconds = totalSeconds % 60;
            console.log(`天数: ${days}`);
            console.log(`小时: ${hours}`);
            console.log(`分钟: ${minutes}`);
            console.log(`秒数: ${seconds}`);
            res.data[resKey].applyTiem = `距离报名开始：`;
            res.data[resKey].applyTiem2=`${days}天${hours}小时${minutes}分${seconds}秒`;
          }
          if (res.data[resKey].acvitityStatus === 4){
            res.data[resKey].applyTiem = `已结束`;
          }
          if (res.data[resKey].acvitityStatus === 5){
            res.data[resKey].applyTiem = `已截至`;
          }
          if (res.data[resKey].acvitityStatus === 6){
            res.data[resKey].applyTiem = `名额已满`;
          }
        }
        if (data.page === 1) {
          data.eventsAttr = res.data
        } else {
          data.eventsAttr = data.eventsAttr.push(res.data)
        }

        console.log(data)
      })
    }
    const onClickTab = ({title}) => {
      console.log(title)

      if (title === '全部') {
        data.page = 1
        data.liebiaoListType = null
        liebiao();

      } else if (title === '报名中') {
        data.page = 1
        data.liebiaoListType = 2
        liebiao();

      } else if (title === '即将报名') {
        data.page = 1
        data.liebiaoListType = 3
        liebiao();

      } else if (title === '已结束') {
        data.page = 1
        data.liebiaoListType = 4
        liebiao();
      }
    }

    const jump = (e) => {
      console.log(e.jumpStyle)
      console.log(e)
      if (e.jumpStyle === "详情") {
        router.push({name: 'eventDetails', query: {id: e.item.id}});
      }

    }
    onMounted(() => {
      window.addEventListener('scroll', handleScroll); // 监听滚动事件
      // liebiao()
      // handleScrollsetInterval()
      liebiao();
    });
    const handleScrollsetInterval = () => {
      // data.dsq = setInterval(() => {
      //   liebiao();
      // }, 1000);
    }
    onUnmounted(()=>{
      clearInterval(data.dsq);
      console.log('定时器已终止');
    })


    onBeforeUnmount(() => {
      window.removeEventListener('scroll', handleScroll); // 组件销毁前移除事件监听
    });
    const handleScroll = () => {
      const scrollY = window.scrollY; // 获取滚动条距离顶部的距离
      const windowHeight = window.innerHeight; // 获取窗口可视区域的高度
      const documentHeight = document.documentElement.scrollHeight; // 获取整个文档的高度
      // if (scrollY + windowHeight >= documentHeight - 0) {
      //   // 当滚动条距离底部小于等于 20px 时执行相应的逻辑
      //   // 例如加载更多数据等操作
      //   console.log('000000')
      //   data.page ++
      //   console.log(data.page)
      //   liebiao()
      // }
      // if (scrollY  === 0) {
      //   // 当滚动条距离底部小于等于 20px 时执行相应的逻辑
      //   // 例如加载更多数据等操作
      //   console.log('1111111111111')
      //   data.page = 1
      //   console.log(data.page)
      //   liebiao()
      //
      // }
    };
    return {
      handleScroll,
      activeName,
      dataAttr,
      data,
      onClickTab,
      liebiao,
      jump,
      handleScrollsetInterval,
    };
  },

};
</script>

<style lang="scss" scoped>
img {
  display: block;
  width: 100%;
  height: 100%;
}

.van-tab__panel {
  padding-bottom: 200px;
}

.van-tabs__line {
  bottom: 0.1rem !important;
}

.bmzList {
  width: 375 * 2px;
  height: 116 * 2px;
  //background: #fff;
  //background: #EAEAEA;
  margin-top: 20px;
  padding: 8 * 2px;
  display: flex;
  overflow: hidden;
  box-shadow: 0px 2*2px 6*2px 0px rgba(240, 244, 255, 100);

  .ledtImg {
    width: 110 * 2px;
    border-radius: 4 * 2px;
    overflow: hidden;
    margin-right: 8*2px;
  }

  .topTexts {
    //background: #fff;
    position: relative;

    .topText1 {
      width: 6.3rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 0.4rem;
      font-weight: bold;
      color: #404040;
      margin-bottom: 10px;
    }

    .topText2{
      font-size:28px;color: #999999;
      font-weight: 400;
      margin-bottom: 10px;
    }

    .topText3{
      font-size:28px;color: #999999;
      font-weight: 400;
      margin-bottom: 10px;
    }
    .topText4 {
      display: flex;
      justify-content: space-between;
      position: absolute;
      bottom: 0;
      width: 96%;
      font-size:28px;color: #999999;
      height: 60px;
      align-items: center;

      .topText41 {
        font-size:28px;color: #999999;
        font-weight: 400;
        //color: #999;
        //font-size: 18px;
        //display: flex;
        //justify-content: left;
        //align-items: center;
        //margin-bottom: 6px;
      }

      .topText42 {
        color: #fff;
        background: #EE0A24;
        padding: 10px;
        padding-left: 20px;
        padding-right: 20px;
        border-radius: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .topText4211 {

        color: #EE0A24;
        background: #FCE3E6;
        padding: 10px;
        padding-left: 20px;
        padding-right: 20px;
        border-radius: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .topText4212 {

        color: #FF9502;
        background: #fff;
        border: 1px solid #FF9502;
        padding: 10px;
        padding-left: 20px;
        padding-right: 20px;
        border-radius: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .topText4213 {
        width: 67*2px;
        height: 67*2px;
        background: url(../assets/image/yjs.png);
      }

      .zt2 {
        width: 57*2px;
        height: 57*2px;
        overflow: hidden;
      }
    }
  }
}

.bmzList2 {
  background: #fff;
}

.bmzList3 {
  background-color: #EAEAEA;
}

.anniuyangshixiugai {
  width: 70*2px;
  height: 25*2px;
  border-radius: 50*2px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12*2px;
}

.anniuyangshixiugai1 {
  background: #EE0A24;
  color: #fff;
}

.anniuyangshixiugai2 {
  background: #FCE3E6;
  color: #EE0A24;
}

.anniuyangshixiugai3 {
  border: 1px solid #FF9502;
  color: #FF9502;
}
</style>
