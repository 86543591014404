<template>
  <div style="background:#f8f8f8;margin-bottom: 150px">
    <van-tabs
        v-model:active="name"
        color="#EE0A24"
        line-height="2px"
        @click-tab="onClickTab"
    >
      <!--       title是TAB标签名，根据name来切换展示相应的对面 -->
      <van-tab
          v-for="(item,index) in data.tabAttr"
          :key="index"
          :name="item.name"
          :title="item.title"
      >

      </van-tab>
    </van-tabs>
    <van-tabs v-model:active="active" style="margin-top: 5px" line-width="0"
              v-if="data.dataList && data.dataList.length > 0"  @click-tab="handleTabClick" >
      <van-tab v-for="(item,index) in data.dataList"
               :key="index"
               :title="item.tagName"
               :name="item.id"

      >
      </van-tab>
    </van-tabs>
    <div class="IconBox">
      <div class="Icon" v-for="(item2,index) in data.eventsAttr" :key="index">
        <div class="leftImg">
          <img :src="item2.image" alt="" />
        </div>
        <div class="rightText">
          <div class="rightText-1">
            {{item2.name}}
          </div>
          <div class="rightText-2">
            {{item2.typeValue}}
          </div>
          <div class="rightText-3">
            {{item2.address}}
          </div>
          <div class="rightText-4">
            所属网格：{{item2.gridRegionName}}
          </div>
          <div class="rightText-5">
            <div style="font-size:12px;color: #F90101;border: 1px solid #F90101;border-radius: 4px;padding: 1px 3px 1px 3px;margin-right: 5px" v-for="(item3,index3) in item2.tagName" :key="index3">
              {{ item3 }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <van-empty v-show="data.eventsAttr.length < 1" description="暂无数据"
               style="position: fixed; top: 40px; left: 0;right: 0;bottom: 100px;margin: auto"/>

  </div>
  <tab></tab>
</template>
<script>
import Tab from "@/components/tab.vue";
import {onMounted, reactive, ref} from "vue";
import {communityListGet, communityTag} from "@/api/community";

export default {
  components: {Tab},
  setup(_, context) {
    const data = reactive({
      eventsAttr: [], // 活动列表attr
      active: 0,
      dataList: [{
        tagName: ''
      }],
      tabAttr: [
        {
          title: "全部",
          name: "0",
        },
        {
          title: "党组织",
          name: "1",
        },
        {
          title: "社会组织",
          name: "2",
        },
        {
          title: "国有企业事业单位",
          name: "3",
        },
        {
          title: "民营企业",
          name: "4",
        },
        {
          title: "学校",
          name: "5",
        },
      ],
      page: 1,
      liStyle3: null,

    })
    const name = ref("a");
    const active = ref(0);
    const dataList = [];
    // 在组件挂载后发送请求，并将返回的数据赋值给 dataAttr
    onMounted((type) => {
      document.title = "共同体列表";

      //共同体 - 列表
      communityList();
      communityTagGet();
    });
    const handleTabClick = (item) => {
      // 处理标签点击事件的方法
      console.log('点击了标签', item.name);
      data.tag = item.name
      communityList()
    }
    const communityList = () => {
      let a = {
        type: data.type,
        pageNo: data.page,
        pageSize: 99999,
        gridTagId: data.tag
      }
      communityListGet(a).then(res => {
        console.log(res)
        if (data.page === 1) {
          data.eventsAttr = res.data.records
        } else {
          data.eventsAttr = data.eventsAttr.push(res.data)
        }
        res.data.records.forEach(item => {
          item.tagName = item.tagName?item.tagName.split(',') :[]
          console.log('records',item.tagName);
        })
        console.log(data)
      })
    }
    const communityTagGet = () => {
      let tag = {
        type: data.type,
      }
      communityTag(tag).then(res => {
        console.log(res)
        data.dataList = res.data.list
        console.log("aaaa",data.dataList)
        if( res.data.list.length > 0){
          res.data.list.unshift({
            id:'',
            tagName:"全部"
          })
        }
        data.tag=res.data.list.length>0?res.data.list[0].id:''
        active.value=res.data.list.length>0?res.data.list[0].id:0
        console.log('全部', res.data.list)
        communityList()
      })
    }
    const onClickTab = ({title}) => {
      console.log(title)

      if (title === '全部') {
        data.page = 1
        data.type = null
        // communityList();

      } else if (title === '党组织') {
        data.page = 1
        data.type = 1
        // communityList();

      } else if (title === '社会组织') {
        data.page = 1
        data.type = 2
        // communityList();

      } else if (title === '国有企业事业单位') {
        data.page = 1
        data.type = 3
        // communityList();

      } else if (title === '民营企业') {
        data.page = 1
        data.type = 4
        // communityList()

      } else if (title === '学校') {
        data.page = 1
        data.type = 5
        // communityList();
      }
      communityTagGet();


    }
    return {
      onClickTab,
      data,
      name,
      active,
      dataList,
      handleTabClick,
      communityList,
      communityTagGet,
    };
  }
}
</script>

<style lang="scss" scoped>
.Icon {
  display: flex;
  background: #fff;
  // align-items: center;
  position: relative;
  margin-bottom: 20px;
  border-radius: 8px;
  margin-top: 20px;

  .leftImg {
    width: 220px;
    height: 220px;
    // background: #000;
    margin: 16px;
    border-radius: 8px;
    overflow: hidden;

    img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  .rightText {
    width: 440px;
    // height: 220rpx;
    margin-top: 20px;
    margin-bottom: 20px;
    .rightText-1 {
      color: #404040;
      font-size: 28px;
      font-weight: 600;
      margin-bottom: 4px;
    }

    .rightText-2 {
      font-size: 24px;
      color: #fff;
      margin-bottom: 8px;
      border-radius: 8px;
      background-color: #F90101;
      display: inline-block;
      padding-left: 10px;
      padding-right: 10px;
      padding-bottom: 1px;
    }

    .rightText-3 {
      font-size: 24px;
      margin-bottom: 12px;
    }

    .rightText-4 {
      font-size: 24px;
      margin-bottom: 12px;
    }

    .rightText-5 {
      display: flex;
      flex-wrap: wrap;
      font-size: 24px;
      color: #1890FF;

    }
  }
}

.box {
  height: 80px;
  background-color: #fff;
  display: flex;
  font-size: 24px;
  color: #323232;
  width: 100%;
  overflow-y: hidden;
  position: relative;
  align-items: center;
  //position: fixed;
  top:90rpx;
  .ui {
    white-space: nowrap;
    display: flex;
    align-items: center;
    height: 110px;
    overflow: scroll;
    padding-right: 20px;
    padding-left: 20px;
    .li {
      font-size: 24px;
      font-weight: bold;
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 1px;
      border-radius: 8px;
      color: #6D6D6D;
    }
    .liStyle {
      border: 1rpx solid #1890FF;
      color: #1890FF;
    }
  }
}
.rightText-5Item{
  border: 1rpx solid #1890FF !important;
}
</style>
