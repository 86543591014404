<template>
  <div class="container">
    <iframe class="embedded-page" :src="data.embeddedPageUrl"></iframe>
  </div>
  <tab></tab>
</template>

<script>
import {onMounted, reactive} from "vue";
import {useRoute} from "vue-router";
import tab from "../components/tab.vue";
export default {
  components: {
    tab,
  },
  setup() {
      const data = reactive({
        embeddedPageUrl:null,
      })
      onMounted(()=>{
        // console.log(useRoute().query.url)
        data.embeddedPageUrl = useRoute().query.url2

      })
    return {
      data,
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.embedded-page {
  flex: 1;
  width: 100%;
  border: none;
}
</style>
