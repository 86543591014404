<template>
  <div style="padding-top: 20px">
    <van-form @submit="onSubmit">
      <van-cell-group inset>
        <div v-for="(item,index) in data.diyForm" class="box">
          <div v-show="item.columnType === 1" class="textBox">
            <van-field
                :key="index"
                v-model="item.name"
                :label="item.columnName"
                placeholder="请输入"
            />
          </div>
          <div v-show="item.columnType === 2" class="shuziBox">
            <van-field :key="index"
                       v-model="item.name"
                       :label="item.columnName"
                       :name="item.columnName"
                       placeholder="请输入"
                       :type="item.columnType === 2 ?  'number':''"
                inputmode="numeric" />
          </div>
          <div v-show="item.columnType === 3" class="danBox">
            <!--            <van-field-->
            <!--                :key="index"-->
            <!--                v-model="item.name"-->
            <!--                :label="item.columnName"-->
            <!--                :name="item.columnName"-->
            <!--                placeholder="请选择‘单选’"-->
            <!--                readonly-->
            <!--            />-->
<!--            <van-field :label="item.columnName" :name="item.columnName">-->
<!--              <template #input>-->
<!--                <van-radio-group v-model="item.xzList" >-->
<!--                  <van-radio-->
<!--                      v-for="(item2,index2) in item.danSortList"-->
<!--                      :key="index2"-->
<!--                      :name="item2.label"-->
<!--                      checked-color="#FF6767"-->
<!--                      style="padding-top: 10px;"-->
<!--                  >{{ item2.label }}-->
<!--                  </van-radio>-->
<!--                </van-radio-group>-->
<!--              </template>-->
<!--            </van-field>-->

            <van-field
                v-model="item.xzList"
                is-link
                readonly
                name="picker"
                :label="item.columnName"
                placeholder="点击选择"
                @click="item.showPicker = true"
            />
            <van-popup v-model:show="item.showPicker" position="bottom">
              <van-picker
                  :columns="item.danSortList"
                  @confirm="(row, column, event) => onConfirm(item, row, column, event)"
                  @cancel="item.showPicker = false"
              ></van-picker>
            </van-popup>
          </div>
          <div v-show="item.columnType === 4" class="duoBox">
            <!--            <van-field-->
            <!--                :key="index"-->
            <!--                v-model="item.name"-->
            <!--                :label="item.columnName"-->
            <!--                :name="item.columnName"-->
            <!--                placeholder="请选择‘多选’"-->
            <!--                readonly-->
            <!--            />-->
            <van-field :label="item.columnName" :name="item.columnName">
              <template #input>
                <van-checkbox-group v-model="item.xzList" direction="horizontal">
                  <!-- 多个复选框 -->
                  <van-checkbox
                      v-for="(item2,index2) in item.duoSortList"
                      :key="index2"
                      :name="item2.label"
                      shape="square"
                      checked-color="#FF6767"
                      style="margin-bottom: 10px;">{{ item2.label }}
                  </van-checkbox>
                </van-checkbox-group>
              </template>
            </van-field>
          </div>
        </div>
      </van-cell-group>
      <div style="margin: 16px;">
        <van-button color="#FF6767" block native-type="submit" round type="primary">
          提交
        </van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import {onMounted, reactive, ref} from "vue";
import {useRoute} from "vue-router";
import {pioneerAcvitityListGet} from "@/api";
import {acvitityListApplyPost} from "@/api/signUpFome";
import router from "@/router";
import {showNotify} from "vant";

export default {
  setup() {
    const checked = ref('1');
    const groupChecked = ref([]);

    const showPicker = ref(false);
    const result = ref('');
    const form = reactive({
      map:[],
    })
    const data = reactive({
      diyForm: [],
      hdID:'',
    })
    onMounted(() => {
      data.hdID = useRoute().query.id
      acquireForm(data.hdID)
    });
    const acquireForm = (e) => {
      pioneerAcvitityListGet(e).then(res => {
        console.log(res.data.acvitity.applySet)
        let applySet = res.data.acvitity.applySet
        applySet.forEach((item) => {
          item.xzList = []
          item.columnMust === 0 ? item.columnMust = false : item.columnMust = true
          console.log(item.xzList)
        });

        res.data.acvitity.applySet.forEach((item)=>{
          if(item.columnType === 3){
            item.danSortList.forEach((item2)=>{
              item2.text = item2.label
              item2.value = item2.label
            })

          }
            })
        data.diyForm = res.data.acvitity.applySet
      })
    }
    const onSubmit = (values) => {
      form.map=[]
      data.diyForm.forEach((element) => {
        console.log(element);
        form.map.push({
          "columnName": element.columnName,
          "columnValue":
              element.columnType === 1 || element.columnType === 2 ?  element.name :
              element.columnType === 3 || element.columnType === 4 ?  String(element.xzList)  : null
        })
      });
      let aaa = {
        map:form.map
      }
      acvitityListApplyPost(data.hdID,aaa,localStorage.getItem('phone')).then(res=>{
        console.log(res)
        console.log("2222222222")
        console.log("11111",data.hdID)
        console.log("222222222222222",res.code)
        if(res.code === 200){
          showNotify({ type: 'success', message: '提交成功' });
        }else {
          showNotify({ type: 'success', message: res.msg });
        }
        router.replace({ name: 'myActivity'})
      })
    };
    const onConfirm = (item,row, column, event) => {
      // result.value = selectedOptions[0]?.text;
      // console.log("aaaa",row.selectedValues[0])
      // console.log("aaaa",column)
      // console.log("aaaa",item)
      item.xzList = row.selectedValues[0]
      console.log("aaaa",item)
      item.showPicker = false;
    };
    return {
      data,
      form,
      checked,
      acquireForm,
      onSubmit, groupChecked,
      showPicker,
      onConfirm,
      result

    }
  }
}
</script>

<style lang="scss" scoped>

</style>
