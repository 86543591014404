<template>
  <van-form @submit="onSubmit">
    <div class="box">
      <van-cell-group inset>
        <van-field
            v-model="form.userName"
            name="userName"
            label="用户名"
            placeholder="请填写用户名"
            :rules="[{ required: true, message: '请填写用户名' }]"
        />
        <van-field
            v-model="form.password"
            type="password"
            name="password"
            label="密码"
            placeholder="请填写密码"
            :rules="[{ required: true, message: '请填写密码' }]"
        />
        <van-field
            v-model="form.nickName"
            name="nickName"
            label="昵称"
            placeholder="请填写昵称"
            :rules="[{ required: true, message: '请填写昵称' }]"
        />
        <van-field
            v-model="form.phone"
            name="phone"
            label="手机号"
            placeholder="请填写手机号"
            :rules="[{ required: true, message: '请填写手机号' }]"
        />

        <van-field name="sex" label="性别:">
          <template #input>
            <van-radio-group v-model="form.sex" direction="horizontal">
              <van-radio checked-color="#FF6767" name="0">男 </van-radio>
              <van-radio checked-color="#FF6767" name="1">女 </van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field
            v-model="result"
            is-link
            readonly
            name="birthday"
            label="出生日期"
            placeholder="选择时间"
            @click="showPicker = true"
        />
        <van-popup v-model:show="showPicker" position="bottom">
          <van-date-picker :min-date="new Date(1945, 0, 1)"
                           :max-date="new Date(2099, 11, 31)" @confirm="onConfirm" @cancel="showPicker = false" />
        </van-popup>
      </van-cell-group>
    </div>
    <div style="margin: 16px;">
      <van-button  color="#FF6767" round block type="primary" native-type="submit">
        提交
      </van-button>
    </div>
  </van-form>
</template>

<script>
import {reactive, ref} from 'vue';
import {PioneerLoginInsert} from "@/api";
import router from "@/router";
import {showNotify} from "vant";

export default {
  setup() {
    const result = ref('');
    const showPicker = ref(false);
    const form = reactive({
      userName:'',
      password:'',
      nickName:'',
      phone:'',
      sex:'0',
      birthday:'',
    })

    const onConfirm = ({ selectedValues }) => {
      result.value = selectedValues.join('/');
      showPicker.value = false;
    }
    const onSubmit = (values) => {
      console.log('submit', values);
      if (!isValidMobile(form.phone)) {
        alert('请输入真实有效的手机号！');
        return;
      }
      let data = {
        phone:form.phone,
        userName:form.userName,
        password:form.password,
        nickName:form.nickName,
        sex:form.sex,
        birthday:form.birthday,
      }
      PioneerLoginInsert(data).then(res=>{
        console.log("11111111111")
        console.log("onSubmit",res,"成功")

      })
      showNotify({ type: 'success', message: '注册成功'});
      // 跳转到登录页
      router.push({name: 'login'});
    };
    const isValidMobile = (mobile) => {
      const regex = /^1[3-9]\d{9}$/;
      return regex.test(mobile);
    };

    return {
      form,
      onConfirm,
      result,
      showPicker,
      onSubmit,
    };
  },
};
</script>

<style scoped lang="scss">

</style>
