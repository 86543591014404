<template>
  <div>
    <div style="padding-top: 20px">
      <div class="topBox" >
        <div class="topTestBox">
          <div class="">兑换单号</div>
          <div class="">{{data.exchangeDetailsList.exchangeNumber}}</div>
        </div>
        <div class="topTestBox">
          <div class="">兑换时间</div>
          <div class="">{{data.exchangeDetailsList.goodsTime}}</div>
        </div>
        <div class="topTestBox">
          <div class="">兑换状态</div>
          <div class="topZt" style="color:#FF463B ; border-color: #FF463B;background: rgba(255, 70, 59, 0.2);"  v-if="data.exchangeDetailsList.state === 1">
            待领取
          </div>
          <div class="topZt"  style="color:#7AC756 ; border-color: #7AC756;background: rgba(122, 199, 86, 0.2);"   v-if="data.exchangeDetailsList.state === 2">
            已领取
          </div>
          <div class="topZt"  style="color:#ccc ; border-color: #ccc;background: rgba(204,204, 204, 0.2);"   v-if="data.exchangeDetailsList.state === 3">
            已拒绝
          </div>
        </div>
        <div class="topTestBox topTestBoxStyle" style="background: #F5F6F7">
          <div class="topImg">
            <img :src="data.exchangeDetailsList.goodsImage"
                 alt="" />
          </div>
          <div class="" style="width: 390rpx;">
            <div class="" style="margin-bottom: 40rpx; white-space: nowrap;text-overflow: ellipsis;overflow: hidden;word-break: break-all;font-size: 16px;">
              {{data.exchangeDetailsList.goodsName}}
            </div>
            <div class="" style="padding-top: 20px">
              x {{data.exchangeDetailsList.goodsNum}}
            </div>
          </div>
        </div>
        <div class="topTestBox" style="justify-content: flex-end;margin-bottom:0;">
          <div class=""  style="font-size: 14px;">
            共{{data.exchangeDetailsList.goodsNum}}件，兑换积分：<span style="font-size: 18px;color: #FF6637;font-weight: 600;">{{data.exchangeDetailsList.goodsIntegral}}</span>
          </div>
        </div>
      </div>
      <div class="topBox" style="padding-top: 0;padding-bottom: 0">
        <div class="topTestBox" style="margin-bottom: 0;padding-top: 0;padding-bottom: 0">
          <div class="">
            领取地址
          </div>
          <div class="">
            {{data.exchangeDetailsList.getAddress}}
          </div>
        </div>
      </div>
      <div class="topBox" v-if="data.type ===1">
        <div class="topTestBox">
          <div class="">领取人</div>
          <div class="">{{data.exchangeDetailsList.getPerson}}</div>
        </div>
        <div class="topTestBox">
          <div class="">领取人电话</div>
          <div class="">{{data.exchangeDetailsList.getPhone}}</div>
        </div>
        <div class="topTestBox">
          <div class="">领取日期</div>
          <div class="">{{data.exchangeDetailsList.getTime}}</div>
        </div>
        <div class="topTestBox">
          <div class="">备注</div>
          <div class="">{{data.exchangeDetailsList.remark}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {onMounted, reactive} from "vue";
import {getDetails} from "@/api/integralDetail";
import {useRoute} from "vue-router";
export default {
  setup() {

    const onClickTab = () => {
      getDetailsList()
    };
    const data = reactive({
      exchangeDetailsList:[],
      type:1
    })
    onMounted(()=>{
      let data1 = {
        id:useRoute().query.id,
        type:1
      }
      // data.type=useRoute().query.type
       data.type=useRoute().query.type
      getDetailsList(data1);
    })
    const getDetailsList = (data1) => {
      getDetails(data1).then(res=>{
        console.log("222",res.data.recordVO)
        data.exchangeDetailsList = res.data.recordVO
        console.log(data.exchangeDetailsList)
      })
    }

    return {
      data,
      onClickTab,
      getDetailsList,
    };
  },
};
</script>

<style lang="scss" scoped>
.topBox {
  width: 100%;
  padding: 30px;

  .topTestBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 28px;
    margin-bottom: 20px;

    .topZt {
      width: 130px;
      height: 24*2px;
      background: #F1F9EE;
      padding-left: 20px;
      padding-right: 20px;
      color: #7AC756;
      font-size: 24px;
      padding-top: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #7AC756;
    }
  }

  .topTestBoxStyle {
    //background: #F5F6F7;
    padding: 26px;
    justify-content: left;
    margin-bottom: 30px;

    .topImg {
      width: 108*2px;
      height: 71*2px;
      overflow: hidden;
      margin-right: 26px;
    }
  }
}

img {
  display: flex;
  width: 100%;
  height: 100%;
}
</style>
