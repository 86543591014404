import request from '../utils/requst'
//共同体列表
export function communityListGet(data){
    return request({
        url: 'api/applet/community/page',
        method: 'get',
        params:data
    })
}

//共同体标签
export function communityTag(data){
    return request({
        url: 'api/applet/community/tagName',
        method: 'get',
        params:data
    })
}
