<template>
  <div style="background: #f8f8f8;margin-bottom: 150px">
    <!-- 顶部区域 -->
    <div class="topBox">
      <!-- 标题个人中心 -->
      <div class="topText">个人中心</div>
      <!-- 底纹图片 -->
      <div class="topImg">
        <img v-show="phone===''" :src="photo" alt=""/>
        <img v-show="phone!==''" alt="" src="../assets/image/head.b2cfa49.png"/>
      </div>
      <div v-show="!phone" class="topName">
        <span @click="navigateToMiniProgram">登录</span> /
        <span @click="zhuceProgram">注册</span>
      </div>
      <div v-show="phone" class="topName">
        <span @click="navigateToMiniProgram">{{ phone }}</span>
      </div>
    </div>
    <!--
         @click="jump({id:item.id,title:item.title})"传参是用于判断下一步进行的操作，需要什么值传什么，
         亲测直接传item是Proxy对象用起来更复杂。
     -->
    <!-- 菜单条 -->
    <div class="menuListBox">
      <div class="menuList">
        <van-cell
            v-for="item in menuListAttr"
            :key="item.id"
            :icon="item.imgUrl"
            :title="item.title"
            class="menuList"
            is-link
            @click="jump({id:item.id,jump:item.title})"
        >
          <template #left-icon>
            <van-icon class="search-icon" name="search"/>
          </template>
          <template #value>
            <span :style="{ color: contentColor }">{{ item.text }}</span>
          </template>
          <!-- 自定义右侧角标，如果不好看的话这里需要提供原图 -->
          <!-- <template #right-icon>
                        <van-icon name="search" class="search-icon" />
                    </template> -->
        </van-cell>
      </div>
    </div>

  </div>
  <tab></tab>
</template>

<script>
import tab from "../components/tab.vue";
import {onMounted, reactive} from 'vue'
import router from "@/router";
import {PioneerLoginByid} from "@/api";
import {useRoute} from "vue-router";
import {pioneerGet} from "@/api/myIntegral";
import {loginPhoneGet} from "@/api/IndividualCenter";
import {showConfirmDialog, showToast} from "vant";

export default {
  computed: {
    registration() {
      return registration
    }
  },
  components: {
    tab,
  },
  data() {
    return {
      contentColor: "red",
      menuListAttr: [
        {
          imgUrl: "https://jjh-jjh.oss-cn-hangzhou.aliyuncs.com/2023/%E5%9B%BE%E6%A0%87/1.png",
          title: "我的积分",
          text: "",
          id: 1,
        },
        {
          imgUrl: "https://jjh-jjh.oss-cn-hangzhou.aliyuncs.com/2023/%E5%9B%BE%E6%A0%87/2.png",
          title: "积分商城",
          text: "",
          id: 2,
        },
        {
          imgUrl: "https://jjh-jjh.oss-cn-hangzhou.aliyuncs.com/2023/%E5%9B%BE%E6%A0%87/3.png",
          title: "我参与的活动",
          text: "",
          id: 3,
        },
        // {
        //   imgUrl: "https://jjh-jjh.oss-cn-hangzhou.aliyuncs.com/2023/%E5%9B%BE%E6%A0%87/4.png",
        //   title: "我的订单",
        //   text: "",
        //   id: 4,
        // },
        // {
        //   imgUrl: "https://jjh-jjh.oss-cn-hangzhou.aliyuncs.com/2023/%E5%9B%BE%E6%A0%87/5.png",
        //   title: "我的消息",
        //   text: "",
        //   id: 5,
        // },
        {
          imgUrl: "https://jjh-jjh.oss-cn-hangzhou.aliyuncs.com/2023/%E5%9B%BE%E6%A0%87/6.png",
          title: "设置",
          text: "",
          id: 6,
        },
      ],
      styleBottomBJ: false,
      token: localStorage.getItem('token'),
      phone: localStorage.getItem('phone'),
      user: null,
      photo: null,
    };
  },
  mounted() {
    this.loginPhoneGetStyle(this.token)
  },
  methods: {
    jump(data) {
      console.log(data.id);
      // window.location.href = data.jumpUlr
      if (data.jump === '我的积分') {
        if (localStorage.getItem('token')) {
          router.push({name: 'myIntegral'})

        } else {
          showConfirmDialog({
            title: '温馨提示',
            message: '此项操作需要登录才可以继续哦，是否前往登录。',
          })
              .then(() => {
                // on confirm
                router.push({name: 'login', query: {type: '活动'}});
              })
              .catch(() => {
                // on cancel
              });
        }
      }
      if (data.jump === '我参与的活动') {
        if (localStorage.getItem('token')) {
          router.push({name: 'myActivity'})
        } else {
          showConfirmDialog({
            title: '温馨提示',
            message: '此项操作需要登录才可以继续哦，是否前往登录。',
          })
              .then(() => {
                // on confirm
                router.push({name: 'login', query: {type: '活动'}});
              })
              .catch(() => {
                // on cancel
              });
        }
      }
      if (data.jump === '积分商城') {
        if (localStorage.getItem('token')) {
          router.push({name: 'integralShop'})
        } else {
          showConfirmDialog({
            title: '温馨提示',
            message: '此项操作需要登录才可以继续哦，是否前往登录。',
          })
              .then(() => {
                // on confirm
                router.push({name: 'login', query: {type: '活动'}});
              })
              .catch(() => {
                // on cancel
              });
        }
      }
      if (data.jump === '我的订单') {
        showToast('订单开发中');
      }
      if (data.jump === '我的消息') {
        showToast('消息开发中');
      }
      if (data.jump === '设置') {
        // settings
        router.push({name: 'settings'})
      }

    },
    loginPhoneGetStyle() {
      if (this.phone) {
        console.log("aaaaa",localStorage.getItem('type'))
        const type = localStorage.getItem('type');
        let ruleType;
        if(type==='0'){
          ruleType = 2
        }else{
          ruleType = 1
        }
        loginPhoneGet(this.phone,ruleType).then(res => {
          if (res.code === 200) {
            if (res.data && res.data.availableIntegral !== undefined) {
              this.availableIntegral = res.data.availableIntegral;
              if (this.availableIntegral) {
                this.menuListAttr[0].text = this.availableIntegral + '积分';
              } else {
                this.menuListAttr[0].text = 0 + '积分';
              }
            } else {
              // 处理没有可用积分的情况
              this.menuListAttr[0].text = 0 + '积分';
            }
            // this.availableIntegral = res.data.availableIntegral
            // for (const resKey in this.menuListAttr) {
            //   console.log(this.menuListAttr[0].text)
            //   if (this.availableIntegral) {
            //     this.menuListAttr[0].text = this.availableIntegral + '积分'
            //   } else {
            //     this.menuListAttr[0].text = ''
            //   }
            //   // if (this.photo.message) {
            //   //   this.menuListAttr[4].text = this.photo.message + '条为未读'
            //   // } else {
            //   //   this.menuListAttr[4].text = ''
            //   // }
            // }
          }

        })
      }

    }

  },
  setup() {
    const state = reactive({
      appId: 'wx3f9ffe1a239f4f74',
      redirectUri: 'your_redirect_uri',
      scope: 'a4569bbc7c6e8b27c3e77e99b4c72cd',
      state: 'wx3f9ffe1a239f4f74',
      miniProgramPath: 'pages/index/index',
    })


    const navigateToMiniProgram = () => {
      router.push({name: 'login'});
    };

    const zhuceProgram = () => {
      router.push({name: 'zhuce'});
    }
    const loginById = (phone) => {
      PioneerLoginByid(data).then(res => {
        console.log(res)
      })
    }
    return {
      navigateToMiniProgram,
      zhuceProgram,
      loginById,
    }
  }
};
</script>

<style lang="scss" scoped>
img {
  display: block;
  width: 100%;
  height: 100%;
}

.search-icon {
  display: block;
  justify-content: center;
  align-items: center;
}

.topBox {
  width: 375 * 2px;
  height: 300 * 2px;
  background: url(../assets/image/bg.0a4b7c0c.png);
  background-size: 100% 100%;
  padding-top: 24 * 2px;
  text-align: center;

  .topText {
    color: #f8f8f8;
    font-size: 0.5rem;
    font-weight: bold;
    margin-bottom: 20 * 2px;
  }

  .topImg {
    width: 100 * 2px;
    height: 100 * 2px;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto;
    margin-bottom: 10 * 2px;
  }

  .topName {
    color: #f8f8f8;
    font-size: 0.4rem;
  }

  .topNameZC {
    color: #f8f8f8;
    font-size: 0.5rem;
  }

}

.menuListBox {
  .menuList{
    line-height: 18*2px;
      .menuList{
        img{
          width: 100px !important;
        }
      }
  }
  .menuList:nth-child(2n) {
    margin-bottom: 20px;
  }

  //   自定义右侧角标，如果不好看的话这里需要提供原图
  //   .search-icon{
  //     border: 1px solid red;
  //   }
}
.van-icon__image{
  margin-top: 2px !important;
}
</style>
