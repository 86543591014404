import request from '../utils/requst'
//活动列表
export function acvitityListGet(data){
    return request({
        url: 'api/applet/pioneer/acvitityList',
        method: 'get',
        params:data
    })
}
//活动详情
export function acvitityDetailsGet(id){
    return request({
        url: 'api/applet/pioneer/acvitityList/details/'+id+'/'+3,
        method: 'get',
    })
}
// 报名
export function acvitityApplyGet(data){
    return request({
        url: 'api/applet/pioneer/acvitityList',
        method: 'post',
        data: data
    })
}
//活动动态
export function huoDongXiangCesAttr(id){
    return request({
        url: 'api/applet/pioneer/acvitityList/dynamic/'+id,
        method: 'Get'
    })
}

//活动评论
export function pinglun(id){
    return request({
        url: 'api/applet/pioneer/acvitityList/comment/'+id,
        method: 'Get'
    })
}
// 活动报名form表单格式
export function pioneerAcvitityListGet(id){
    return request({
        url: 'api/applet/pioneer/acvitityList/'+id,
        method: 'Get'
    })
}
// 个人中心获取头像
export function PioneerLoginByid(phone){
    return request({
        url: 'api/applet/pioneer/login/'+phone,
        method: 'get',
    })
}
// 个人中心获取验证码
export function PioneerCode(data){
    return request({
        url: 'api/applet/pioneer/login/code',
        method: 'post',
        params: data
    })
}
// 个人中心登录
export function PioneerLogin(data){
    return request({
        url: 'api/applet/pioneer/login/denglu',
        method: 'post',
        params: data
    })
}

// 个人中心注册
export function PioneerLoginInsert(data){
    return request({
        url: 'api/applet/pioneer/login/insert',
        method: 'post',
        data: data
    })
}
//参与的活动
export function applyGet(data){
    return request({
        url: 'api/applet/pioneer/my/apply',
        method: 'get',
        params: data
    })
}
//参与的活动
export function applyByIdGet(id){
    return request({
        url: 'api/applet/pioneer/my/'+id,
        method: 'get',
    })
}

//我的积分
export function pionnerIntegral(){
    return request({
        url: 'api/admin/integralDetail/pioneer',
        method: 'get',
    })
}
