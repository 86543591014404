<template>
  <div>
    <div class="topBox">
      <div class="img"><img :src="datas.applyIn.thumbanil" alt=""></div>
      <div class="text">
        <div class="text1">{{ datas.applyIn.acvitityName }}</div>
        <div class="text2">活动时间：{{ datas.acvitityStartTime }} ～ {{ datas.acvitityEndTime }}</div>
        <div class="textiImg">
          <img alt="" src="../assets/image/qianjin.png">
        </div>
      </div>
    </div>
    <div class="fenGeXian"></div>
    <div class="inBox">
      <div class="heXiaoMa">核销码 {{ datas.applyIn.applyCode }}</div>
      <div class="img">
        <img :src="datas.applyIn.qrurl" alt="">
      </div>
      <div class="text">参加活动请出示二维码</div>
    </div>
    <div class="fenGeXian"></div>
    <div class="footBox">
      <div class="baoMingXinXi">
        <div class="title">
          <div class="leftImg"></div>
          <div class="text">报名信息</div>
        </div>
        <div class="texts" v-for="(item,index) in datas.applyIn.applyInfo " :key="index">
          <div class="left">{{ item.colummName }}</div>
          <div class="right">{{ item.colummValue }}</div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import {useRoute} from "vue-router";
import {onMounted, reactive} from "vue";
import {applyByIdGet} from "@/api";

export default {
  setup() {
    const datas = reactive({
      applyIn: '',
    })
    onMounted(() => {
      let id = useRoute().query.id
      applyById(id)

      console.log(useRoute().query.id)
    })
    const applyById = (data) => {
      applyByIdGet(data).then(res => {
        console.log(res)
        datas.applyIn = res.data
        console.log(datas.applyIn)
        datas.acvitityStartTime = datas.applyIn.acvitityStartTime.substring(0, 10)
        datas.acvitityEndTime = datas.applyIn.acvitityEndTime.substring(0, 10)
        console.log(datas.applyIn.applyInfo)
        // let displayContent = '';
        // datas.applyIn.applyInfo.forEach((info, index) => {
        //   displayContent += `${index + 1}. ${info.colummName}: ${info.colummValue}\n`;
        // });

        // console.log(displayContent)

      })
    }
    return {
      applyById,
      datas,
    }
  }
}
</script>

<style lang="scss" scoped>
.topBox {
  padding: 10*2px 13*2px;
  display: flex;
  align-items: center;
  position: relative;

  .img {
    width: 100px;
    height: 100px;
    border-radius: 8px;
    margin-right: 11*2px;
  }

  .text {
    .text1 {
      margin-bottom: 10*2px;
      color: #404040;
      font-size: 14*2px;
      font-weight: bold;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .text2 {
      color: #999;
      font-size: 12*2px;
    }

    .textiImg {
      position: absolute;
      top: 11*2px;
      right: 13*2px;
      width: 16*2px;
      height: 16*2px;
    }
  }
}

.fenGeXian {
  width: 750px;
  height: 20px;
  background: #f5f5f5;
}

.inBox {
  width: 750px;
  height: 278*2px;
  text-align: center;
  padding-top: 60px;

  .heXiaoMa {
    margin-bottom: 13*2px;
    font-size: 18*2px;
  }

  .img {
    width: 147*2px;
    height: 147*2px;
    background: red;
    margin: 0 auto;
    margin-bottom: 13*2px;
  }

  .text {
    color: #6d6d6d;
    font-size: 14*2px;
  }
}

.footBox {
  .baoMingXinXi {
    .title {
      display: flex;
      padding-top: 12*2px;
      padding-bottom: 12*2px;
      border-bottom: 1px solid #f5f5f5;
      align-items: center;

      .leftImg {
        width: 8px;
        height: 40px;
        background: #EE0A24;
        margin-right: 13*2px;
      }

      .text {
        font-size: 14*2px;
        color: #101010;
      }
    }

    .texts {
      padding-top: 10px;
      padding-bottom: 10px;
      padding-left: 18px;
      display: flex;

      .left {
        color: #999;
        font-size: 14*2px;
        margin-right: 18px;
      }

      .right {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
</style>
