<template>
  <div class="box">
    <div class="jumu">
      <div class="touxiang">
      </div>
      <div class="mingzio">金楫先锋</div>
    </div>
    <van-tabs v-model:active="active" swipeable @change="jumpTabBar">
      <van-tab
          v-for="(item,index) in data.tabBarList"
          :key="index"
          :title="item.name"
          >
      </van-tab>
    </van-tabs>
    <div class="head" style="display: flex;justify-content: center;align-items: center;">
      <van-form @submit="onSubmit">
        <van-cell-group inset>
          <van-field
              v-model="phone"
              :rules="[{ required: true, message: '请填写手机号' }]"
              label="账号"
              name="账号"
              placeholder="请输入账号"
          />
          <van-field
              v-model="sms"
              center
              clearable
              label="短信验证码"
              placeholder="请输入短信验证码"
          >
            <template #button>
              <van-button v-show="kaiguan" color="#FF6767" size="small" type="primary"
                          @click="jump({jumpStyle:'验证码获取'})">{{ yzm }}
              </van-button>
              <van-button v-show="!kaiguan" color="#FF6767" disabled size="small" type="primary">{{
                  yzmshuz
                }}
              </van-button>
            </template>
          </van-field>
        </van-cell-group>
        <div style="margin: 16px;">
          <van-button block color="#FF6767" native-type="submit" round type="primary">
            提交
          </van-button>
        </div>
      </van-form>
    </div>
  </div>

</template>

<script>
import {onMounted, reactive, ref} from 'vue';
import {PioneerCode, PioneerLogin} from "@/api"
import router from "@/router";
import {showNotify, showToast} from "vant";
import {useRoute} from "vue-router";
import {loginPhoneGet} from "@/api/IndividualCenter";

export default {
  setup() {
    const active = ref(0);
    console.log("1212",active)
    const phone = ref('');
    const yzm = ref('获取验证码');
    const kaiguan = ref(true);
    const yzmshuz = ref(60);
    const sms = ref('');
    const type = ref('');
    const hdtType = ref('');
    const data =reactive({
      tabBarType:0,
      tabBarList:[{
        name:'群众',
        id:0
      },{
        name:'共同体',
        id:1
      }],
      type:0,
    })
    onMounted(() => {
      localStorage.setItem('type', '0');
      console.log(useRoute())
      if (useRoute().query.hdtType) {
        hdtType.value = useRoute().query.hdtType
      }
    })
    // Tab 切换 事件
    const jumpTabBar = (e)=>{
      console.log(e)
      data.type = e
      localStorage.setItem('type', e);
    }
    const onSubmit = () => {
      const type = localStorage.getItem('type');
      if (sms._value) {
        let data = {
          phone: phone._value,
          verificationCode: sms._value,
          type
        }
        PioneerLogin(data).then(res => {
          if (res.code === 200) {
            console.log("onSubmit", res, "成功")
            localStorage.setItem('token', res.data.jjxf_token);
            localStorage.setItem('phone', res.data.phone);
            showNotify({type: 'success', message: '登录成功'});
            setTimeout(() => {
              router.go(-1)

              // if (type.value === '活动') {
              // } else {
              //   router.replace({name: 'IndividualCenter',});
              // }
            }, 1000)
            // loginPhoneGet(res.data.phone ).then(res=>{
            //   console.log('res',res)
            //   localStorage.setItem('jf', res.data.availableIntegral);
            // })
          } else {
            showToast(res.msg)
          }
        })
      } else {
        showToast('请输入验证码');
      }
    };
    const jump = (e) => {
      console.log(e)
      if (e.jumpStyle === '验证码获取') {
        const type = localStorage.getItem('type');
        if (phone._value) {
          kaiguan.value = false
          let data = {
            phone: phone._value,
            type
          }
          PioneerCode(data).then(res => {
            console.log('jump', res)
            if (res.code === 200) {
              showToast('发送成功');
            }
            yzmshuz.value = 60
            let intervalIds = setInterval(function () {
              // console.log(yzmshuz);
              yzmshuz.value--;
              if (yzmshuz.value === 0) {
                clearInterval(intervalIds); // 在计数到5时清除定时器
                kaiguan.value = true
              }
            }, 1000);
          })

        } else {
          showToast('请输入手机号');
        }
      }
    }

    return {
      data,
      active,
      phone,
      sms,
      yzm,
      yzmshuz,
      kaiguan,
      onSubmit,
      jump,
      jumpTabBar,

    };
  }
};
</script>

<style lang="scss" scoped>
.jumu {
  width: 100%;
  height: 600px;
  background: red;
  overflow: hidden;
  background: url("../assets/image/bg.0a4b7c0c.png") no-repeat center;
  background-size: 100% 100%;

  .touxiang {
    width: 150px;
    height: 150px;
    background: url("../assets/image/logo.aed54edc.png") no-repeat center;
    background-size: 100% 100%;
    margin: 100px auto;
    border-radius: 50%;
  }

  .mingzio {
    text-align: center;
    margin-top: -80px;
    color: #fff;
  }
}

.head {
  padding-top: 40px;
}


</style>
