<template>
  <div>
    <div class="lunBoBox">
      <van-swipe :autoplay="3000" lazy-render>
        <van-swipe-item>
          <img :src="data.exchangeMallList.goodsImage" alt=""/>
        </van-swipe-item>
      </van-swipe>
    </div>
    <div class="lunBoText">
        <div class="lunBoText1">{{ data.exchangeMallList.goodsName }}</div>
        <div class="lunBoText2">
            <div class="lunBoText11">数量：{{ data.exchangeMallList.goodsCount }}</div>
            <div class="lunBoText12"><span>{{ data.exchangeMallList.exchangeIntegral }}</span>积分</div>
        </div>
    </div>
    <div class="fenGeXian"></div>
    <div class="diZhiText">
        <div class="diZhiText1">领取地址：</div>
        <div class="diZhiText2">
            <div class="body2-2-1" v-for="(item, index) in data.exchangeMallList.exchangeAddressValue" :key="index">
              {{ item.exchangeAddress }}
            </div>
        </div>
    </div>
    <!-- <div class="fenGeXian"></div> -->
    <div class="shangPinXiangQingTitle">
        <div class="leftBg"></div>
        <div class="leftText">商品详情</div>
    </div>
    <div style="padding: 26rpx;" v-html="data.exchangeMallList.goodsDescribe"></div>
<!--    <div class="btn" @click="btn">立即兑换</div>-->
    <div class="btn"  @click="show = true" v-if="data.exchangeMallList.availableIntegral >= data.exchangeMallList.exchangeIntegral">立即兑换</div>
    <div class="btn" style="background-color: #F4C6CB" v-else-if="data.exchangeMallList.availableIntegral < data.exchangeMallList.exchangeIntegral">积分不足</div>
    <!-- 弹出层 -->
    <div>
      <van-action-sheet v-model:show="show" title="">
        <div class="box1">
          <div class="box1-1">
            <img :src="data.exchangeMallList.goodsImage" alt="" />
          </div>
          <div class="box1-2">
            <div style="padding-bottom: 40px;">{{ data.exchangeMallList.goodsName }}</div>
            <div style="display: flex;">
              <div style="width: 240px;"> 兑换数量 <van-stepper @change="jump({jumpStyle:'数量',value:count})" v-model="count" style="padding-left: 65px"/></div>
            </div>
          </div>
        </div>
        <div class="box2">
          <div class="box2-1">
            领取地址：
          </div>
          <div class="box2-2">
            <div class="box1-2-1"
                 v-for="(item, index) in data.exchangeMallList.exchangeAddressValue"
                 :key="index"
                 :style="tabHuan === index ? 'color: #1890FF':'color: #000'"
                 @click="jump({ jumpStyle:'地址切换',item:item,index:index})">
              {{ item.exchangeAddress }}
              <div class="box1-2-1-1">
                <img v-if="tabHuan === index" src="https://jjh-jjh.oss-cn-hangzhou.aliyuncs.com/2023/%E5%9B%BE%E6%A0%87/%E5%AF%B9%E5%8B%BE.png" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div class="fenGeXian" style="height: 2px"></div>
        <div class="box3" >
          <div class="box3-1">
            {{ data.exchangeMallList.exchangeIntegral * count }} <span>积分</span>
          </div>
          <div class="box3-2">
            <button style="border: 2px solid red"  v-if="data.exchangeMallList.availableIntegral >= (data.exchangeMallList.exchangeIntegral * count)" @click="getRedemption">立即兑换</button>
            <button style="border: 2px solid #F4C6CB;background-color: #F4C6CB;"  v-else-if="data.exchangeMallList.availableIntegral < (data.exchangeMallList.exchangeIntegral * count)">积分不足</button>
          </div>
        </div>
      </van-action-sheet>

<!--        <van-popup-->
<!--        v-model:show="show"-->
<!--        position="bottom"-->
<!--        :style="{ height: '300px' }"-->
<!--        @open="showToast('open')"-->
<!--        @opened="showToast('opened')"-->
<!--        @close="showToast('close')"-->
<!--        @closed="showToast('closed')"-->
<!--        >-->
<!--        </van-popup>-->
    </div>
  </div>
</template>

<script>
import {onMounted, reactive, ref} from 'vue';
import {showNotify, showToast} from 'vant';
import {getMallDetails, getMallExchange} from "@/api/integralDetail";
import {useRoute} from "vue-router";
import router from "@/router";
export default {
    // data() {
    //     return {
    //         show:false,
    //     }
    // },
    // methods: {
    //     btn() {
    //         this.show = !this.show
    //     }
    // },
  setup() {
    const data = reactive({
      exchangeMallList:[],
      count:1,
    })
    const tabHuan = ref(0);
    const tabHuan2 = ref(0);
    const address = ref(null);
    onMounted(()=>{
      let data1 = {
        id:useRoute().query.id,
      }
      getDetailsList(data1);
    })


    const onClickTab = () => {
      getDetailsList()
    };

    // const images = [
    //   "https://fastly.jsdelivr.net/npm/@vant/assets/apple-1.jpeg",
    //   "https://fastly.jsdelivr.net/npm/@vant/assets/apple-2.jpeg",
    // ];
    // const show = ref(true);
    // const value = ref(1);



    const jump = (e) => {
      if (e.jumpStyle === '数量'){
        tabHuan2.count
      }
      if(e.jumpStyle === '地址切换'){
        tabHuan.value = e.index;
        address.value = e.item.exchangeAddress;
      }

    }
    const count = ref(1)
    const value = ref(1);
    const show = ref(false);
    const getDetailsList = (data1) => {
      getMallDetails(data1).then(res=>{
        data.exchangeMallList = res.data.integralMall
        address.value = data.exchangeMallList.exchangeAddressValue[0].exchangeAddress
        tabHuan.value = 0
      })
    }
    const getRedemption = () => {
      getMallExchange(count.value,data.exchangeMallList.id,address.value).then(res=>{
        console.log(res)
        if(res.code ===200){
          router.push({name: 'exchangeRecord'});
        }
      })
    }
    return {
        show,
        value,
        count,
        data,
        onClickTab,
        getDetailsList,
        tabHuan,
        address,
        jump,
        getRedemption
     };
  },
};
</script>

<style lang="scss" scoped>
img{  width: 750px;height: 460px;}
.lunBoBox {
  width: 750px;
  height: 460px;
  overflow: hidden;
  margin-bottom: 12*2px;
}
.van-swipe {
  width: 100%;
  height: 100%;
}
.lunBoText{
    width: 750px;
    padding: 13*2px;
    .lunBoText1{
        font-family: 16*2px;
        color: #333;
    }
    .lunBoText2{
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
        .lunBoText11{
            font-size: 14*2px;
            color: #333;
        }
        .lunBoText12{
            font-size: 14*2px;
            color: #ee0a24;
            span{
                font-size: 18*2px;
                margin-right: 10px;
                font-weight: bold;
            }
        }

    }

}
.fenGeXian{
        width: 750px;
        height: 20px;
        background: #F5F6F7;
}
.diZhiText{
    padding: 13*2px;
    font-size: 14*2px;
    color: #333;
    display: flex;
    .diZhiText2{
        .diZhiText21{
            margin-bottom: 20px;
        }
    }
}
.shangPinXiangQingTitle{
    padding: 11*2px;
    display: flex;
    align-items: center;
    background: #F5F6F7;
    .leftBg{
        width: 6px;
        height: 40px;
        background: #1890FF;
        margin-right: 9*2px;
    }
    .leftText{
        font-size: 16*2px;
        color: #333;
    }
}
.xiangQingBackground{
    width: 750px;
    height: 450px;
    background: red;
}
.btn{
    width: 320*2px;
    height: 45*2px;
    border-radius: 100px;
    background: #EE0A24;
    position: fixed;
    bottom: 70px;
    left: 0;
    right: 0;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
}
.btn2{
    background: #F4C6CB;
}

.box1 {
  padding-top: 32px;
  display: flex;
  margin-bottom: 30px;
  .box1-1 {
    margin-left: 24px;
    width: 108*2px;
    height: 90*2px;
    margin-right: 36px;
    img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
}
.box2 {
  display: flex;
  padding-left: 24px;
  padding-top: 24px;

  .box2-1 {
    font-size: 28px;
  }

  .box2-2 {
    font-size: 28px;

    .box2-2-1 {
      padding-bottom: 20px;
    }
    .box1-2-1{
      margin-bottom: 20px;
      width: 480px;
      position: relative;
      .box1-2-1-1{
        position: absolute;
        top: 3px;
        right: -50px;
        margin: auto;
        width: 32px;
        height: 32px;
        overflow: hidden;
        img{
          display: block;
          width: 100%;
          height: 100%;
        }
      }
    }
    .box1-2-1style{
      color: #1890FF;
    }
  }
}

.box3 {
  display: flex;
  padding-left: 26*2px;
  padding-bottom: 30px;

  .box3-1 {
    padding-top: 30px;
    color: #ff6637;
    font-size: 18*2px;
    padding-right: 30px;

    span {
      font-weight: bold;
      text-align: center;
      font-size: 14*2px;
      padding-right: 40px;
    }
  }

  .box3-2 {
    button {
      background-color: red;
      height: 90px;
      width: 215*2px;
      position: fixed;
      bottom: 10px;
      right: 19px;
      margin: auto;
      color: #fff;
      border-radius: 100px;
    }
  }
}
</style>
