
<template>

<!-- <div>
  <van-tabbar v-model="active" @change="onChange">
  <van-tabbar-item name="首页" icon="wap-home-o">首页</van-tabbar-item>
  <van-tabbar-item name="头条报道" icon="newspaper-o">头条报道</van-tabbar-item>
  <van-tabbar-item name="星火学院" icon="bookmark-o">星火学院</van-tabbar-item>
  <van-tabbar-item name="我的" icon="contact">我的</van-tabbar-item>
</van-tabbar>
</div> -->
<van-tabbar route class="xgStyle" active-color="#EE0A24" inactive-color="#B7B7B7">
  <van-tabbar-item replace to="/" icon="home-o" @click="jump({jumpStyle:'首页'})">首页</van-tabbar-item>
  <van-tabbar-item replace to="/headLine" icon="newspaper-o">头条报道</van-tabbar-item>
  <van-tabbar-item replace to="/eventsList" icon="bookmark-o">活动报名</van-tabbar-item>
<!--  <van-tabbar-item replace :to="{ path: '/externalConnection', query: { url: 'http://xxlgcwx.sipac.gov.cn/', }}" icon="newspaper-o">头条报道</van-tabbar-item>-->
<!--  <van-tabbar-item replace :to="{ path: '/externalConnection2', query: { url2: 'http://xxlgcwx.sipac.gov.cn/', }}" icon="bookmark-o">活动报名</van-tabbar-item>-->
  <van-tabbar-item replace to="/IndividualCenter" icon="contact">我的</van-tabbar-item>
  <!-- <div v-for="(item,index) in names.list" :key="index" @click="gaibian">{{item}}</div> -->
</van-tabbar>
</template>
<!--<van-tabbar-item :to="{ path: '/externalConnection', query: { id: 123, name: 'test' }}" replace icon="newspaper-o">头条报道</van-tabbar-item>-->
  <script>
import { ref, reactive  } from 'vue';
// import { showToast } from 'vant';
export default {
  setup() {
    const active = ref(0);
    const icon = {
      active: 'https://fastly.jsdelivr.net/npm/@vant/assets/user-active.png',
      inactive:
        'https://fastly.jsdelivr.net/npm/@vant/assets/user-inactive.png',
    };
    // 声明数组
    const names = reactive({
      list:["iwen","ime" ,"frank"]
    })
    // 更变数组
    // function gaibian() {
    //   names.list=["1","2" ,"3"]
    // }
    // const router = inject('router');
    // const onChange = (index) => {
    //   // showToast(`${index}`)
    //   if (`${index}` === '首页') {
    //     console.log(1)
    //     console.log(inject('router'));
    //     // router.push('/');
    //   }
    //   if (`${index}` === '头条报道') {
    //     console.log(2)
    //     window.location.href = 'http://xxlgcwx.sipac.gov.cn/';
    //   }
    //   if (`${index}` === '星火学院') {
    //     console.log(3)
    //     window.location.href = 'http://xxlgcwx.sipac.gov.cn/';
    //   }
    //   if (`${index}` === '我的') {
    //     console.log(4)
    //     // $router.push('/IndividualCenter');
    //   }
    // }
    const jump = (e)=>{
      if (e.jumpStyle === '首页'){

        console.log('6666666')
      }
      if (e.jumpStyle === '头条报道'){

      }
      if (e.jumpStyle === '活动报名'){

      }
      if (e.jumpStyle === '我的'){

      }
    }
    return {
      active,
      icon,
      names,
      jump,
      // gaibian,
      // onChange,
    };
  },
};

</script>
<style scoped lang="scss">
    .xgStyle{
      padding-bottom: 40px;
    }
</style>
