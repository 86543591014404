import request from '../utils/requst'
// 积分中心 - 明细
export function pioneerGet(data){
    return request({
        url: 'api/admin/integralDetail/pioneer',
        method: 'get',
        params:data
    })
}

export function pioneerDetailsGet(data){
    return request({
        url: 'api/admin/integralDetail/pioneerDetails',
        method: 'get',
        params:data
    })
}
