import request from '../utils/requst'
//活动详情-动态-查看 接口 - 动态
export function dynamicGet(id){
    return request({
        url: 'api/applet/pioneer/acvitityList/dynamic/'+id,
        method: 'get',
    })
}
// 活动详情 - 动态 - 点赞
export function likeAmountGet(data){
    return request({
        url: 'api/applet/pioneer/acvitityList/likeAmount',
        method: 'get',
        params:data
    })
}
//活动详情-评论-查看  接口 - 评论
export function commentGet(id){
    return request({
        url: 'api/applet/pioneer/acvitityList/comment/'+id,
        method: 'get',
    })
}
// 活动详情 - 评论 - 点赞
export function commentAmountGet(data){
    return request({
        url: 'api/applet/pioneer/acvitityList/commentAmount',
        method: 'get',
        params:data
    })
}
// 活动详情 - 评论 - 发送评论
export function commentAddGet(data){
    return request({
        url: 'api/applet/pioneer/acvitityList/comment/add',
        method: 'post',
        params:data
    })
}
// 活动详情 - 取消报名
export function updateStateGet(acvitityId,phone){
    return request({
        url: 'api/applet/pioneer/acvitityList/updateState/'+acvitityId+'/'+phone,
        method: 'post'
    })
}

