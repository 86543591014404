import axios from 'axios'
import router from "@/router";


// create an axios instance
const service = axios.create({
    // baseURL: 'http://192.168.0.101:8080/', // url = base url + request url
    baseURL: 'https://jjh.kindledme.com/', // url = base url + request url
    // headers: { 'Content-Type': 'application/json' },
    // withCredentials: true, // send cookies when cross-domain requests
    timeout: 5000 // request timeout
})

// request interceptors
service.interceptors.request.use(
    config => {
        // do something before request is sent
        config.headers['token'] = localStorage.getItem('token') ? localStorage.getItem('token') : ''
        return config
    },
    error => {
        // do something with request error
        console.log(error) // for debug
        return Promise.reject(error)
    }
)

// response interceptor
service.interceptors.response.use(
    /**
     * If you want to get http information such as headers or status
     * Please return  response => response
     */

    /**
     * Determine the request status by custom code
     * Here is just an example
     * You can also judge the status by HTTP Status Code
     */
    response => {
        const res = response.data
        const {config} = response
        // 成功时
        if (res.code === 200){
            // get请求不提示
            if (config.method !== 'get'){

            }
        }else if (res.code === 403) {

        } else if (res.code === 401) {
            console.log('1111111')
            router.push({name: 'login',});
        }
        return res
    },
    error => {
        console.log('err' + error) // for debug

        return Promise.reject(error)
    }
)

export default service
