<template>
  <head>
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
    />
  </head>
  <router-view />
  <!-- <van-tabbar route>
    <van-tabbar-item replace to="/" icon="home-o">标签</van-tabbar-item>
    <van-tabbar-item replace to="/eventDetails" icon="search">标签</van-tabbar-item>
  </van-tabbar> -->
</template>
<script>
// import { ref } from 'vue';

// export default {
//   setup() {
//     const active = ref(0);
//     return { active };
//   },
// };

</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
* {
  margin: 0;
  padding: 0;
  font-size: 14 * 2px;
  box-sizing: border-box;
}
img {
  display: block;
  width: 100%;
  height: 100%;
}
</style>
