import request from '../utils/requst'
// 积分中心-积分明细
export function pioneerDetailsGet(){
    return request({
        url: 'api/admin/integralDetail/pioneerDetails',
        method: 'get',
    })
}

// 积分中心-积分商城
export function pioneerShopGet(data ){
    return request({
        url: 'api/applet/pioneer/shop/list',
        method: 'get',
        params:data
    })
}
// 积分中心-商品兑换记录
export function pioneershopRecordGet( data){
    return request({
        url: 'api/applet/integral/exchange/page',
        method: 'get',
        params:data
    })
}

// 积分中心- 荣誉兑换记录
export function pioneerMallRecordGet(data){
    return request({
        url: 'api/applet/integral/record/page',
        method: 'get',
        params:data
    })
}

// 积分中心- 兑换记录详情
export function getDetails(data){
    return request({
        url: 'api/applet/integral/record/info/'+data.id+'/'+data.type,
        method: 'get',
    })
}

// 积分中心- 兑换商品详情
export function getMallDetails(data){
    return request({
        url: 'api/applet/integral/mall/info/'+data.id,
        method: 'get',
    })
}

// 积分中心- 兑换接口
export function getMallExchange(count,id,address){
    return request({
        url: 'api/applet/integral/productExchange?count='+count+'&id='+id+'&address='+address +'&type='+1,
        method: 'post',
    })
}
