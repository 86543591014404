<template>
  <div class="box">
    <!-- 巨幕 -->
    <div class="topJuMu">
      <img :src="datas.xiangqingIn.thumbanil" alt=""/>
    </div>
    <!-- 内容区域 -->
    <div class="contentBox">
      <div class="topText">
        {{ datas.xiangqingIn.acvitityName }}
      </div>
      <div class="huoDongShiJian">
        <div class="left">
          <div class="img"><img alt="" src="../assets/image/sj.png"/></div>
          <div class="text">活动时间：</div>
        </div>
        <div class="text">{{ datas.acvitityStartTime }} ~ {{ datas.acvitityEndTime }}</div>
      </div>
      <div class="huoDongShiJian">
        <div class="left">
          <div class="img"><img alt="" src="../assets/image/grzx.png"/></div>
          <div class="text">活动名额：</div>
        </div>
        <div class="text">{{ datas.xiangqingIn.places }}</div>
      </div>
      <div class="huoDongDiDian ">
        <div class="left" v-if="datas.xiangqingIn.locationNum>0">
          <div class="img"><img alt="" src="../assets/image/dw.png"/></div>
          <div class="text">活动地点：</div>
        </div>
        <div class="right" v-if="datas.xiangqingIn.locationNum>0">
          <div class="text">{{datas.xiangqingIn.locationNum}}个地点可选</div>
          <div class="img"></div>
          <!--          <div class="button">查看地图</div>-->
        </div>
      </div>
      <div class="huoDongDiZhiListBox">
        <div v-for="(item,index) in datas.xiangqingIn.addresss" :key="index" class="huoDongDiZhiList" style="margin-bottom: 10px;">
          <div class="huoDongDiZhiList-1">
            <div class="img"><img alt="" src="../assets/image/dh.png"/></div>
            <div class="text">{{ item.address }}</div>
          </div>
          <div class="huoDongDiZhiList-2">
            <div class="img"><img alt="" src="../assets/image/lx.png"/></div>
            <div class="text">{{ item.tel ? item.tel : '' }}
              <span class="button" style="position: absolute; right: 0;">地点{{ index + 1 }}</span>
            </div>
          </div>
<!--          <div class="button">地点{{ index + 1 }}</div>-->
        </div>
      </div>
    </div>
    <!-- 分割线 -->
    <div class="fenGeXian"></div>
    <!-- 已报名的文字条 -->
    <div class="yiBaoMing">已提交报名 {{ datas.xiangqingIn.applyNum }} / {{ datas.xiangqingIn.places }}</div>
    <!-- 报名人列表以及活动详情活动评论区域 -->
    <div class="huoDongQuYU">
      <div class="bobbob">
        <div class="box" :class="{ 'hidden': datas.xiangqingIn.applyNum === 0 }" :style="{ 'padding': datas.xiangqingIn.applyNum !== 0 ? '5px 10px' : '0' }">
          <div v-for="n in datas.xiangqingIn.applyNum" :key="n" class="imgList">
            <img @click="jump({jumpStyle:'已报名列表跳转'})" alt="" src="@/assets/image/head.b2cfa49.png"/>
          </div>
<!--          <div-->
<!--              v-for="(item,index) in datas.xiangqingIn.photoList"-->
<!--              :key="index"-->
<!--              class="imgList"-->
<!--           >-->
<!--            <img v-show="item.photo === ''" alt="" src="@/assets/image/head.b2cfa49.png"/>-->
<!--            <img v-show="item.photo !== ''" :src="item.photo" alt=""/>-->
<!--          </div>-->
        </div>
      </div>
      <div class="tabListBox">
        <div class="tabList">
          <van-tabs v-model:active="active" color='#EE0A24' line-height="2px" @click-tab="onClickTab">
            <van-tab class="styleBottom" title="活动详情">
              <div id="container"
                   style="width:100%;padding:20px;margin-bottom: 60px">
              </div>
            </van-tab>
            <van-tab :title="datas.hddtAttr_length" class="styleBottom">
              <div class="pingLunBoxs">
                <div v-for="(item,index) in datas.hddtAttr" :key="index" class="hddtAttr">
<!--                  <div class="useImg">-->
<!--                    <img :src="item.photo" alt="">-->
<!--                  </div>-->
                  <div class="touImage">
                    <div class="useImg" style="border-radius: 50%;color: #fff;padding-bottom: 4rpx; font-size: 18px">
                      {{ (item.photo || '').slice(0, 1) }}
                    </div>
                  </div>
                  <div class="useIn">
                    <div class="useIn_namebox">
                      <div class="useIn_name">{{ item.photo }}</div>
                      <div class="useIn_name2">
                        <div class="buttonImg" @click="jump({jumpStyle:'动态点赞',item:item})">
                          <img v-show="item.state === 0" alt="" src="@/assets/image/dianzai1.png">
                          <img v-show="item.state === 1" alt="" src="@/assets/image/dianzai2.png">
                        </div>
                        <div class="dzShuliang">{{ item.likeCount }}</div>
                      </div>
                    </div>
                    <div class="text2_2">{{ item.dynamicContent }}</div>
                    <div class="huoDongXiangCeBox">
                      <div v-for="(item2,index2) in item.images" :key="index2" class="huoDongXiangCes"
                           @click="jumpImgStyle(item2)">
                        <img :src="item2" alt="">
                      </div>
                    </div>
                  </div>
                </div>
                <van-empty v-show="datas.hddtAttr.length < 1" description="暂无动态"/>
              </div>
            </van-tab>
            <van-tab :title="datas.reviewList_length" class="styleBottom">
              <div class="pingLunBox">
                <div v-for="(item,index) in datas.reviewList" :key="index" class="pingLun">
                  <div class="touXiang">
                    <img :src="item.photo" alt="">
                  </div>
<!--                  <div class="textQu">-->
<!--                    <div class="name">{{ item.nickName }}</div>-->
<!--                    <div class="text">-->
<!--                      {{ item.content }}-->
<!--                    </div>-->
<!--                  </div>-->

                  <div>
                    <div class="textQu">
                      <div class="photoImage" v-if="item.headImageUrl" >
                        <img :src="item.headImageUrl" alt="" />
                      </div>
                      <div class="name">
                        {{ item.gridUserName }}
                      </div>
                    </div>
                    <div class="text" style="margin-top: 10px">
                      {{item.content}}
                    </div>
                  </div>

                  <div class="dianZan">
                    <div class="dianZanImg" @click="jump({jumpStyle:'评论点赞',item:item})">
                      <img v-show="item.state === 0" alt="" src="@/assets/image/dianzai1.png"
                           style="margin-right: 4px;">
                      <img v-show="item.state === 1" alt="" src="@/assets/image/dianzai2.png"
                           style="margin-right: 4px;">
                    </div>
                    <div>{{ item.dynum }}</div>
                  </div>
                </div>
                <van-empty v-show="datas.reviewList.length < 1" description="暂无评论快去发表吧！"/>
              </div>
            </van-tab>
          </van-tabs>
        </div>
        <!-- 底部按钮区域 -->
        <!--    <div v-if="active !== 2" class="buttonBox" style="z-index: 99999">-->
        <div class="buttonBox" style="z-index: 9">
          <div class="FX" @click="jump({jumpStyle:'分享'})">
            <div class="FXimg">
              <img alt="" src="@/assets/image/fx.png">
            </div>
            <div class="FXtext">分享</div>
          </div>
          <!--      state-->
          <div v-show="datas.xiangqingIn.acvitityStatus === 3" :class="
      datas.xiangqingIn.acvitityStatus === 3 ? 'FXbutton2':
      ''">{{
              datas.xiangqingIn.acvitityStatus === 3 ? '即将报名' :
                  ''
            }}
          </div>
          <div v-show="datas.xiangqingIn.acvitityStatus === 1 || datas.xiangqingIn.acvitityStatus === 2"
               :class="
      datas.xiangqingIn.acvitityStatus === 1 ? 'FXbutton':
       datas.xiangqingIn.acvitityStatus === 2 ? 'FXbutton3':
      datas.xiangqingIn.acvitityStatus === 1 &&  datas.xiangqingIn.acvitityInte < datas.jf ? 'FXbutton4':
      ''" @click="jump({
      jumpType:
      datas.xiangqingIn.acvitityStatus === 1 ? '立即报名' :
      datas.xiangqingIn.acvitityStatus === 2 ? '取消报名' :
       datas.xiangqingIn.acvitityStatus === 1 && datas.xiangqingIn.acvitityInte < datas.jf ? '积分不足' :
      ''
      })">{{
              datas.xiangqingIn.acvitityStatus === 1 ? '立即报名' :
                  datas.xiangqingIn.acvitityStatus === 2 ? '取消报名' :
                      datas.xiangqingIn.acvitityStatus === 1 && datas.xiangqingIn.acvitityInte < datas.jf ? '积分不足' :
                          ''
            }}
          </div>
          <div
              v-show="
          datas.xiangqingIn.acvitityStatus === 4 ||
          datas.xiangqingIn.acvitityStatus === 5 ||
          datas.xiangqingIn.acvitityStatus === 6 "
              :class="
      datas.xiangqingIn.acvitityStatus === 4 ||
      datas.xiangqingIn.acvitityStatus === 5 ||
      datas.xiangqingIn.acvitityStatus === 6? 'FXbutton4':
      ''">
            {{
              datas.xiangqingIn.acvitityStatus === 4 ? '已结束' :
                  datas.xiangqingIn.acvitityStatus === 5 ? '报名截止' :
                      datas.xiangqingIn.acvitityStatus === 6 ? '名额已满' :
                          ''
            }}
          </div>
        </div>

      </div>
    </div>

    <div v-if="active === 2" class="faSongPingLunBox">
      <div class="uImg">
        <img alt="" src="@/assets/image/head.b2cfa49.png">
      </div>
      <div class="uInput">
        <input v-model="datas.faSongName" placeholder="说说妳的看法吧~" type="text">
        <div class="button" @click="faSong(datas.faSongName)">
          <div class="img">
            <img alt="" src="https://jjh-jjh.oss-cn-hangzhou.aliyuncs.com/2023/%E5%9B%BE%E6%A0%87/fs.png">
          </div>
          <div class="text">发送</div>
        </div>
      </div>
    </div>
  </div>
  <van-share-sheet
      v-model:show="datas.showShare"
      :options="datas.options"
      style="z-index: 99999"
      title="立即分享给好友"
      @select="onSelect"
  />
</template>

<script>
import {onMounted, reactive, ref, watch} from 'vue';
import {showToast, showImagePreview, showConfirmDialog, showNotify} from 'vant';
import {acvitityDetailsGet, huoDongXiangCesAttr} from "@/api";
import {useRoute} from 'vue-router';
import router from "@/router";
import {
  commentAddGet,
  commentAmountGet,
  commentGet,
  dynamicGet,
  likeAmountGet,
  updateStateGet
} from "@/api/eventDetails";

export default {
  data() {
    return {
      baoMingListAttr: [],
      FXbutton: 'FXbutton',

      jf: localStorage.getItem('jf') ? localStorage.getItem('jf') : '',
    };
  },
  setup(_, context) {
    const datas = reactive({
      xiangqingIn: '',
      dongtaiIn: '',
      hdid: '',
      hddtAttr_length: undefined,
      reviewList: [],
      reviewList_length: undefined,
      faSongName: null,
      options: [
        {name: '微信', icon: 'wechat'},
        {name: '微博', icon: 'weibo'},
        {name: '复制链接', icon: 'link'},
        {name: '分享海报', icon: 'poster'},
        {name: '二维码', icon: 'qrcode'},
      ],
      showShare: false,
    })
    const id = ref('');
    const eventDetails = ref('');
    // watch: {
    //   faSongName(newValue, oldValue) {
    //     console.log(newValue);
    //     console.log(oldValue);
    //     this.faSongName = newValue
    //   }
    // },
    watch(datas.faSongName, (newValue, oldValue) => {
      console.log(newValue);
      console.log(oldValue);
      datas.faSongName = newValue
    })
    const getEventDetails = async () => {
      try {
        const response = await axios.get(`/api/event/${id.value}`);
        eventDetails.value = response.data; // 假设响应数据为活动详情
      } catch (error) {
        console.error(error);
      }
    };
    const active = ref(0);
    onMounted(() => {
      datas.hdid = useRoute().query.id
      let id = useRoute().query.id
      // 上半部分详情
      xiangqing(id); // 接口 - 基本信息 - 活动详情
      acquireData(id); // 接口 - 活动动态
      commentGetData(id); // 接口 - 活动评论
    });
    // 获取活动基本信息 接口 - 基本信息 - 活动详情
    const xiangqing = (data) => {
      acvitityDetailsGet(data).then(res => {
        if(res.code === 200){
          console.log('活动基本信息', res.data)
          // console.log(res.data.acvitity.acvitityStartTime)
          // 富文本 渲染
          let content = res.data.acvitity.content; // 后端传来的富文本内容
          let container = document.getElementById("container"); // 获取容器元素
          container.innerHTML = content; // 将富文本内容赋值给容器的 innerHTML 属性

          datas.acvitityStartTime = res.data.acvitity.acvitityStartTime.substring(0, 10)
          datas.acvitityEndTime = res.data.acvitity.acvitityEndTime.substring(0, 10)
          datas.xiangqingIn = res.data.acvitity
          console.log(datas.xiangqingIn)
        }else {
          showNotify({type: 'warning', message: res.msg});
        }

      })
    }
    // 获取活动动态
    const acquireData = (e) => {
      dynamicGet(e).then(res => {
        console.log('活动动态', res)
        if(res.data.length===0 ){
          datas.hddtAttr_length = `活动动态(${res.data.dynamic})`
          datas.hddtAttr = []
        }else {
          datas.hddtAttr_length = `活动动态(${res.data.dynamic.length})`
          datas.hddtAttr = res.data.dynamic
        }
        console.log(datas.hddtAttr_length)


      })
    }
    // 获取活动评论
    const commentGetData = (e) => {
      commentGet(e).then(res => {
        console.log('活动评论', res)
        datas.reviewList_length = `活动评论(${res.data.comment.length})`
        datas.reviewList = res.data.comment

      })
    }
    // 活动动态-点赞
    const dynamicStateGiveDynamicState = (e) => {
      console.log(e)
      let p = {
        id: e
      }
      likeAmountGet(p).then(res => {
        console.log('动态点赞', res)
        acquireData(datas.hdid);
      })
    }
    // 活动评论-点赞
    const dynamicStateGiveALike = (e) => {
      console.log(e)
      let p = {
        id: e
      }
      commentAmountGet(p).then(res => {
        console.log('评论点赞', res)
        commentGetData(datas.hdid);
      })
    }
    //取消报名
    const updateState = (id,phone) => {
      updateStateGet(id,phone).then(res => {
        console.log("取消报名",res)
        window.location.reload();
      })
    }
    const onClickTab = ({title}) => {

    };
    // const showImagePreview = showImagePreview
    // function jump(data){
    //   console.log(data);
    //   showImagePreview(['https://fastly.jsdelivr.net/npm/@vant/assets/apple-1.jpeg'])
    // }
    // 点击将图片传入图片地址以传入到方法内，用于展示
    function jumpImgStyle(data) {
      showImagePreview([data]);
    }

    // // 活动相册 数组
    // const dongtai = (data) => {
    //   // 获取FORM表单数据
    //   huoDongXiangCesAttr(data).then(res => {
    //     console.log(res)
    //     // datas.dongtaiIn = res.data
    //   })
    // }

    // 点赞，点赞按钮切换
    function dianZanImgType() {
      console.log('123')
    }

    // 消息发送喽~
    function faSong(data) {
      if (localStorage.getItem('token')) {
        showToast('发送成功！')
        let p = {
          acvitityId: datas.hdid,
          content: data
        }
        commentAddGet(p).then(res => {
          console.log('发送消息', res)
          commentGetData(datas.hdid);
          datas.faSongName = null
        })
      } else {
        showConfirmDialog({
          title: '温馨提示',
          message: '此项操作需要登录才可以继续哦，是否前往登录。',
        })
            .then(() => {

              // const query2 = { hdtType: '活动' };
              // console.log('Navigating to login with query:');  // 调试输出
              // router.push({ name: 'login', query: });
              // on confirm
              router.push({name: 'login', query: {hdtType: '活动'}});
            })
            .catch(() => {
              // on cancel
              // console.log('Navigating to login with query:');  // 调试输出

            });
      }

    }

    const jump = (data) => {
      if (localStorage.getItem('token')) {
        if (data.jumpType === '立即报名') {
          if (localStorage.getItem('token')) {
            router.push({name: 'signUpFome', query: {id: datas.hdid,phone:localStorage.getItem('phone')}});
          } else {
            showConfirmDialog({
              title: '温馨提示',
              message: '此项操作需要登录才可以继续哦，是否前往登录。',
            })
                .then(() => {
                  // on confirm
                  router.push({name: 'login', query: {type: '活动'}});
                  // router.push({name: 'login', query: {hdtType: '活动'}});
                })
                .catch(() => {
                  console.log('Navigating to login with query:');  // 调试输出
                  // on cancel
                });
          }
        }
        if (data.jumpType === '取消报名') {
          updateState(datas.xiangqingIn.id,localStorage.getItem('phone'))
        }
        if (data.jumpStyle === '动态点赞') {
          dynamicStateGiveDynamicState(data.item.id)
        }
        if (data.jumpStyle === '已报名列表跳转') {
          router.push({name: 'myActivity'});
        }
        if (data.jumpStyle === '评论点赞') {
          dynamicStateGiveALike(data.item.id)
        }
        if (data.jumpStyle === '分享') {
          // showToast('分享,暂未开放，如需分享点击右上角');
          // console.log(window.wx)
          datas.showShare = true;
        }
      } else {
        showConfirmDialog({
          title: '温馨提示',
          message: '此项操作需要登录才可以继续哦，是否前往登录。',
        })
            .then(() => {
              // on confirm
              router.push({name: 'login'})
            })
            .catch(() => {
              // on cancel
            });
      }

    }
    const onSelect = (option) => {
      showToast(option.name + '分享,暂未开放，如需分享点击右上角');
      datas.showShare = false;
    };
    return {
      id,
      jump,
      datas,
      active,
      faSong,
      onSelect,
      // dongtai,
      xiangqing,
      onClickTab,
      acquireData,
      jumpImgStyle,
      eventDetails,
      dianZanImgType,
      commentGetData,
      getEventDetails,
      dynamicStateGiveALike,
      dynamicStateGiveDynamicState,
      updateState,
    };
  },

  methods: {},
};
</script>

<style lang="scss" scoped>
.topJuMu {
  width: 375 * 2px;
  height: 232 * 2px;
  position: relative;
  z-index: 1;
}

.contentBox {
  padding: 14 * 2px 16 * 2px 0 16 * 2px;
  margin-top: -16 * 2px;
  position: relative;
  z-index: 2;
  background: #fff;
  border-radius: 40px 40px 0 0;

  .topText {
    font-size: 16 * 2px;
    font-weight: bold;
    margin-bottom: 14 * 2px;
  }

  .huoDongShiJian {
    display: flex;
    align-items: center;
    margin-bottom: 16 * 2px;
    line-height: 21px;

    .left {
      display: flex;
      align-items: center;

      .img {
        width: 16 * 2px;
        height: 16 * 2px;
        margin-right: 3 * 2px;
      }

      .text {
        color: #999;
        font-size: 14 * 2px;
        width: 70*2px;

      }
    }

    .text {
      font-size: 14 * 2px;
      color: #101010;
    }
  }

  .huoDongDiDian {
    display: flex;
    align-items: center;
    margin-bottom: 9 * 2px;

    .left {
      display: flex;
      align-items: center;

      .img {
        width: 16 * 2px;
        height: 16 * 2px;
        margin-right: 3 * 2px;
      }

      .text {
        color: #999;
        font-size: 14 * 2px;
      }
    }

    .right {
      display: flex;
      align-items: center;

      .text {
        font-size: 14 * 2px;
        color: #101010;
      }

      .img {
        width: 18 * 2px;
        height: 18 * 2px;
      }

      .button {
        color: #F90101;
      }
    }
  }

  .huoDongDiZhiListBox {
    position: relative;
    margin-bottom: 10 * 2px;

    .huoDongDiZhiList {
      width: 344 * 2px;
      //height: 64 * 2px;
      background: #f8f8f8;
      padding: 8 * 2px 10 * 2px;

      .huoDongDiZhiList-1,
      .huoDongDiZhiList-2 {
        margin-bottom: 9 * 2px;
        display: flex;
        position: relative;

        .img {
          width: 17 * 2px;
          height: 17 * 2px;
          margin-right: 4 * 2px;
        }

        .text {
          color: #101010;
          font-size: 14 * 2px;
          .button {
            justify-content: flex-end;
            background: #F8DFDF;
            border-radius: 20 * 2px 0 0 0;
            color: #F90101;
            padding: 4 * 2px 8 * 2px;
            padding-left: 16 * 2px;
          }
        }
      }

    }
  }
}

.fenGeXian {
  width: 100%;
  height: 10 * 2px;
  background: #f8f8f8;
}

.yiBaoMing {
  padding: 16 * 2px;
  font-size: 14 * 2px;
  color: #000;
  font-weight: bold;
}

.huoDongQuYU {
  background: #f8f8f8;

  .bobbob {
    //height: 96px;
    //overflow: hidden;

    .box {
      background: #fff;
      display: flex;
      overflow-x: hidden;
      overflow-y: hidden;
      overflow-x: scroll;
      //padding-bottom: 30px;
      border-top: 1px solid #f8f8f8;

      .imgList {
        width: 30 * 2px;
        height: 30 * 2px;
        margin-right: 4 * 2px;
        border-radius: 50%;
        overflow: hidden;
        flex: 0 0 30 * 2px;
      }
    }
  }

  .tabListBox {
    padding-top: 20px;

    .tabList {

      .styleBottom {
        //margin-bottom: 200px;
        #container {
          //margin-bottom: 200px;
        }
      }

      .hddtAttr {
        padding: 16*2px;
        display: flex;
        background: #fff;
        margin-bottom: 200px;

        .touImage{
          align-items: center;
          .useImg {
            width: 32*2px;
            height: 32*2px;
            border-radius: 20*2px;
            display: flex;
            background-color: #1890FF;
            overflow: hidden;
            justify-content: center;
            align-items: center;
          }
        }

        .useIn {
          margin-left: 10*2px;
          width: 300*2px;

          .useIn_namebox {
            height: 30*2px;
            line-height: 30*2px;
            display: flex;

            .useIn_name:nth-child(1) {

              width: 80%;
            }

            .useIn_name2 {
              width: 19%;
              display: flex;
              align-items: center;
              justify-content: flex-end;

              .buttonImg {
                width: 16*2px;
                height: 16*2px;
                overflow: hidden;
                margin-right: 10px;
              }

            }
          }

          .text2_2 {
            width: 100%;
            display: flex;
            align-items: center;
            height: 30*2px;
          }

        }
      }

      .huoDongXiangCeBox {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 10*2px;

        .huoDongXiangCes {
          width: 80*2px;
          height: 80*2px;
          margin: 10*2px;
          border-radius: 10px;
          overflow: hidden;
        }

        .huoDongXiangCes:nth-child(3n-2) {
          margin-left: 0px;
        }
      }

      .pingLunBox {
        background: #fff;

        .pingLun {
          padding: 15*2px;
          display: flex;
          position: relative;

          .touXiang {
            width: 30*2px;
            height: 30*2px;
            border-radius: 50%;
            overflow: hidden;
            margin-right: 20px;
          }

          .textQu {
            display: flex;
            align-items: center;
            .photoImage {
              width: 60rpx;
              height: 60rpx;
              border-radius: 8rpx;
              margin-right: 20rpx;
              overflow: hidden;
              display: flex;
              justify-content: center;
              align-items: center;

            }
            .name {
              color: #a8a8a8;
              font-size: 28rpx;
              text-align: center; /* 水平居中 */
              display: flex;
              align-items: center; /* 垂直居中 */
            }
          }


          .dianZan {
            position: absolute;
            right: 30px;
            top: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 12*2px;
            color: #7b7b7b;

            .dianZanImg {
              width: 16*2px;
              height: 16*2px;
              margin-right: 4px;
            }
          }
        }
      }

      .pingLunBoxs {
        margin-bottom: 200px;
      }
    }
  }
}

.buttonBox {
  widows: 100;
  height: 70*2px;
  background: #fff;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  padding-top: 7*2px;
  padding-left: 18*2px;
  padding-bottom: 14*2px;
  padding-right: 18*2px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .FX {
    width: 30*2px;
    height: 30*2px;
    color: #999;
    font-size: 14*2px;

  }

  .FXbutton {
    width: 275*2px;
    height: 45*2px;
    border-radius: 50*2px;
    background: red;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
  }

  .FXbutton2 {
    width: 275*2px;
    height: 45*2px;
    border-radius: 50*2px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #FF9502;
    color: #FF9502;
  }

  .FXbutton3 {
    width: 275*2px;
    height: 45*2px;
    border-radius: 50*2px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #EE0A24;
    color: #EE0A24;
  }

  .FXbutton4 {
    width: 275*2px;
    height: 45*2px;
    border-radius: 50*2px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #D9D9D9;
    color: #929292;
  }

}

.faSongPingLunBox {
  height: 160px;
  width: 750px;
  //position: fixed;
  //bottom: 0;
  //left: 0;
  //right: 0;
  //margin: auto;
  display: flex;
  align-items: center;
  padding-left: 18*2px;
  padding-right: 18*2px;
  background: #fff;
  margin-bottom: 200px;

  .uImg {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin-right: 9*2px;
  }

  .uInput {
    width: 304*2px;
    height: 34*2px;
    border-radius: 40px;
    border: 1px solid #e8e8e8;
    display: flex;
    overflow: hidden;

    input {
      border: none;
      width: 244*2px;
      padding-left: 20px;
    }

    .button {
      display: flex;
      align-items: center;

      .img {
        width: 30px;
        height: 30px;
        margin-right: 2px;
      }
    }
  }
}
</style>
