import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
// 引入px自动转换rem适配移动端
import 'amfe-flexible'
// 全局vnt，本项目是vue3+vant-ui开发的
import vant from 'vant';
import 'vant/lib/index.css';
createApp(App).use(router).use(vant).mount('#app')
