<template>
  <div style="background: #f8f8f8;">
      <!-- 内容列表信息 -->
      <div class="newsList" >
        <div class="news" v-for="(item,index) in data.headLineList" :key="index" :class="{'news1':index > 0}"
             @click="news({item: item})" >
<!--             头条详情接口-->
          <div class="newsTop">
            <img :src="item.coverImage" class="newsImg" />
            <div class="newsTitle">
              {{ item.title }}
              <div class="newsInfo">
                {{ item.profile }}
              </div>
            </div>
          </div>
          <div class="newsD" style="font-size:28px;color: #999999;">
            <text v-if="item.createTime">{{ item.createTime }}</text>
            <text v-else>{{ item.updateTime }}</text>
            <div class="browse">
              <img src="../assets/image/预览.png" class="siteIcon" />
              {{ item.readNum }}
            </div>
          </div>
        </div>
      </div>
      <van-empty v-show="data.headLineList.length < 1" description="暂无数据"
                 style="position: fixed; top: 40px; left: 0;right: 0;bottom: 100px;margin: auto"/>
  </div>
  <div style="margin-top: 10px">
    <tab></tab>
  </div>
</template>

<script>
import {headLineGet} from "@/api/headLine";
import {onMounted, reactive, ref} from "vue";
import router from "@/router";
import Tab from "@/components/tab.vue";
import {showConfirmDialog} from "vant";

export default {
  components: {Tab},
  setup() {
    const active = ref(0);
    const onClickTab = () => {
      headLineRecord()
    };
    const data = reactive({
      headLineList:[],
      page: 1,
    })
    onMounted(()=>{
      headLineRecord();
    })
    const headLineRecord = () => {
      let data1 = {
        platform: 3,
        pageNo: data.page,
        pageSize: 99999
      }
      headLineGet(data1).then(res=>{
        if (data.page === 1) {
          data.headLineList=res.data.records
        } else {
          data.headLineList = data.headLineList.push(res.data.records)
        }
      })

    };
    const news = (e) => {
      router.push({name: 'headLineInfo', query: {id: e.item.id,read: e.item.isRead }})
    };
    return {
      data,
      active,
      onClickTab,
      news,
      headLineRecord,
    };
  },
}
</script>

<style lang="scss" scoped>
.newsList {
  background-color: #FFFFFF;
  padding: 0 20px;
  margin-bottom: 150px;
}

.news {
  padding: 20px 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.news1 {
  border-top: 5px solid #F5F5F5;
}

.newsTop {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.newsImg {
  //width: 120px;
  //height: 120px;
  //border-radius: 5px;

  width: 110 * 2px;
  height: 109 * 2px;
  border-radius: 4 * 2px;
  overflow: hidden;
  margin-right: 8*2px;
}

.newsTitle {
  //width: 550px;
  //color: #333333;
  width: 6.3rem;
  //white-space: nowrap;
  //overflow: hidden;
  //text-overflow: ellipsis;
  font-size: 0.4rem;
  font-weight: bold;
  color: #404040;
  //margin-bottom: 12px;
}

.newsInfo {

  font-size:28px;
  font-weight: 400;
  color: #999999;

  margin-top: 20px;
  //font-size: 28px;
  //color: #999999;
  display: -webkit-box !important;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.newsD {
  width: 100%;
  font-size: .3rem;
  color: #101010;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.browse {
  display: flex;
  align-items: center;
}

.siteIcon {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.bottomBox {
  width: 100%;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #999999;
}

.nullBox {
  margin-top: 100px;
  width: 100vw;
  height: 25vh;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.nullImg {
  width: 300px;
  height: 260px;
}

.nullText {
  width: 100%;
  text-align: center;
  color: #999999;
}
</style>
