<template>
  <div class="box">
    <van-tabs v-model:active="active" @click-tab="onClickTab" color="#EE0A24" title-active-color="#EE0A24">
      <van-tab title="收入">
        <div class="listBox">
          <div class="list" v-for="(item,index) in data.mxListsr" :key="index">
            <div class="listText1">{{ item.actionTypeValue }}</div>
            <div class="listText2">{{ item.registerTime }}</div>
            <div class="listText3">+ {{ item.obtainIntegral }}</div>
          </div>
        </div>
        <van-empty v-show="data.mxListsr.length < 1"  style="position: fixed;top: 100px;left: 0;right: 0;bottom: 0;margin: auto"  description="暂无数据" />

      </van-tab>
      <van-tab title="支出">
        <div class="listBox">
          <div class="list" v-for="(item,index) in data.mxListzc" :key="index">
            <div class="listText1">{{ item.actionTypeValue }}</div>
            <div class="listText2">{{ item.registerTime }}</div>
            <div class="listText3">- {{ item.obtainIntegral }}</div>
          </div>
        </div>
        <van-empty v-show="data.mxListzc.length < 1"  style="position: fixed;top: 100px;left: 0;right: 0;bottom: 0;margin: auto"  description="暂无数据" />

      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import {onMounted, reactive, ref} from "vue";
import { showToast } from "vant";
import {pioneerDetailsGet} from "@/api/integralDetail";
export default {
  setup() {
    const active = ref(0);
    const onClickTab = ({ title }) => {
      data.mxListsr=[]
      data.mxListzc=[]
      pioneerDetailsGetlist()
    }
    const data = reactive({
      mxList:[],
      mxListsr:[],
      mxListzc:[],
    })

    onMounted(()=>{
      pioneerDetailsGetlist();
    })
    const pioneerDetailsGetlist = () => {
      pioneerDetailsGet().then(res=>{
        console.log(res)
        res.data.forEach((item)=>{
          if (item.actionType === 1){
            console.log('1')
            data.mxListsr.push(item)
          }
          if (item.actionType === -1){
            console.log('2')
            data.mxListzc.push(item)
          }
        })
        data.mxList=res.data

      })
    }
    return {
      data,
      active,
      onClickTab,
      pioneerDetailsGetlist,
    };
  },
};
</script>

<style lang="scss" scoped>
.box{
  background: #f8f8f8;
  .listBox{
    padding-top: 20px;
    .list{
      width: 750px;
      height: 67*2px;
      background: #fff;
      border-bottom: 1px solid #f5f5f5;
      padding: 11*2px 17*2px;
      position: relative;
      .listText1{
        margin-bottom: 10px;
        font-size: 14*2px;
        color: #333;
      }
      .listText2{
        font-size: 12*2px;
        color: #999;
      }
      .listText3{
        position: absolute;
        right: 24*2px;
        top: 12*2px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .list:last-child{
      border: none;
    }
  }

}
</style>
