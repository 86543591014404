import request from '../utils/requst'
//头条列表
export function headLineGet(data){
    return request({
        url: 'api/applet/headline/pagePioneer',
        method: 'get',
        params:data
    })
}

//头条详情
export function headLineInfoGet(data){
    return request({
        url: 'api/applet/headline/infoPioneer?id='+data.id,
        method: 'get',
    })
}

//头条
export function headLineReadNumGet(data){
    return request({
        url: 'api/applet/headline/infoPioneerNum?id='+data.id,
        method: 'post',
    })
}
