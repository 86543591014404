<template>
  <div style="background: #f8f8f8">
    <!-- 菜单条 -->
    <div class="menuListBox">
      <div class="menuList">
        <van-cell
          :icon="item.imgUrl"
          :title="item.title"
          is-link
          v-for="item in menuListAttr"
          :key="item.id"
          @click="jump({id:item.id,jumpStyle:item.title,jumpUlr:'http://xxlgcwx.sipac.gov.cn/regional-org'})"
          class="menuList"
        >
          <template #left-icon>
            <van-icon name="search" class="search-icon" />
          </template>
          <!-- <template #value>
            <span :style="{ color: contentColor }">{{ item.text }}</span>
          </template> -->
          <!-- 自定义右侧角标，如果不好看的话这里需要提供原图 -->
          <!-- <template #right-icon>
                        <van-icon name="search" class="search-icon" />
                    </template> -->
        </van-cell>
        <div class="tuiCchuBtn" @click="jump({jumpStyle:'退出登录'})">退出登录</div>
      </div>
    </div>
  </div>
</template>

<script>
import tab from "../components/tab.vue";
import {showConfirmDialog, showToast} from 'vant';
import router from "@/router";
export default {
  components: {
    tab,
  },
  data() {
    return {
      contentColor: "red",
      menuListAttr: [
        {
          imgUrl: "",
          title: "关于金楫先锋",
          text: "1000积分",
          id: 1,
        },
        {
          imgUrl: "",
          title: "清除缓存",
          text: "",
          id: 2,
        },
      ],
      styleBottomBJ:false,
    };
  },

  methods: {
    jump(e) {
        console.log(e);

        if (e.jumpStyle === "关于金楫先锋" ){
          showToast('关于金楫先锋 暂不开放');
        }
        if (e.jumpStyle === "清除缓存"){
          showToast('清除缓存 暂不开放');
        }
        if (e.jumpStyle === "退出登录"){
              showConfirmDialog({
              title: '退出登录',
              message:
                  '确认退出登录吗？',
              })
              .then(() => {
                  // on confirm
                // 清空缓存 退出登录
                localStorage.clear();
                router.replace({ name: 'login' });
              })
              .catch(() => {
                  // on cancel
              });
        }


        // if (data.jumpStyle ==='我的积分') {
        //     console.log('我的积分'); // 输出目标对象
        // }else if (data.jumpStyle ==='退出登录') {
        //     showConfirmDialog({
        //     title: '退出登录',
        //     message:
        //         '确认退出登录吗？',
        //     })
        //     .then(() => {
        //         // on confirm
        //     })
        //     .catch(() => {
        //         // on cancel
        //     });
        // }else{
        //     // window.location.href = data.jumpUlr
        // }
    }
  },
};
</script>

<style lang="scss" scoped>
img {
  display: block;
  width: 100%;
  height: 100%;
}
.menuListBox {
  .menuList:nth-child(2n) {
    // margin-bottom: 20px;
  }
  .tuiCchuBtn{
    background: #fff;
    color: #FF5A57;
    height: 60*2px;
    width: 750px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  //   自定义右侧角标，如果不好看的话这里需要提供原图
  //   .search-icon{
  //     border: 1px solid red;
  //   }
}
</style>
