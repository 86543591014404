<template>
  <div class="container">
    <iframe class="embedded-page" :src="data.embeddedPageUrl"></iframe>
  </div>
  <tab></tab>
</template>

<script>
import {onMounted, reactive} from "vue";
import {useRoute} from "vue-router";
import tab from "../components/tab.vue";
export default {
  components: {
    tab,
  },
  setup() {
      const data = reactive({
        embeddedPageUrl:null,
      })
      onMounted(()=>{
        console.log(useRoute().query.url)
        data.embeddedPageUrl = useRoute().query.url

      })
    return {
      data,
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.embedded-page {
  flex: 1;
  width: 100%;
  border: none;
}
html{
  position:relative;
  width:100%;
  height:100%;
  overflow:hidden;
}
body{
  position:fixed;
  top:0;
  left:0;
  width:100%;
  height:100%;
  overflow:auto;
  -webkit-overflow-scrolling:touch;
}

</style>
