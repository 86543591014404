<template>
  <div style="background: #f8f8f8;">
<!--    <van-tabs-->
<!--      v-model:active="active"-->
<!--      @click-tab="onClickTab"-->
<!--      color="#EE0A24"-->
<!--      title-active-color="#EE0A24"-->
<!--    >-->
<!--      <van-tab title="商品兑换">-->
        <div class="IconBox">
          <div class="Icon" v-for="(item,index) in data.exchangeList" :key="index" @click="code({item:item,index:index+1})">
            <div class="clearfix">
              <div class="clearfixleft">
                兑换单号 {{item.exchangeNumber ? item.exchangeNumber : 0}}
              </div>

              <div class="clearfixleftright"
                   style="color:#FF463B ; border-color: #FF463B;background: rgba(255, 70, 59, 0.2);"
                   v-if="item.state === 1">
                待领取
              </div>
              <div class="clearfixleftright"
                   style="color:#7AC756 ; border-color: #7AC756;background: rgba(122, 199, 86, 0.2);"
                   v-if="item.state === 2">
                已领取
              </div>
              <div class="clearfixleftright"
                   style="color:#ccc ; border-color: #ccc;background: rgba(204,204,204,0.2);"
                   v-if="item.state === 3">
                已拒绝
              </div>
            </div>
            <div class="leftImg">
              <img :src="item.goodsImage" alt="" />
            </div>
            <div class="rightText">
              <div class="rightText-1">
                {{item.goodsName}}
              </div>
              <div style="display: flex">
                <div class="rightText-3">
                  兑换数量：x{{item.goodsNum}}
                </div>
                <div class="rightText-4">
                  {{item.goodsIntegral? item.goodsIntegral: 0}}积分
                </div>
              </div>
            </div>
          </div>
          <van-empty v-show="data.exchangeList.length < 1"  style="position: fixed;top: 100px;left: 0;right: 0;bottom: 0;margin: auto"  description="暂无数据" />
        </div>
<!--      </van-tab>-->
<!--      <van-tab title="荣誉兑换">-->
<!--        <div class="IconBox">-->
<!--          <div class="Icon" v-for="(item,index) in data.mallList" :key="index" @click="code({item:item,index:index+2})">-->
<!--            <div class="clearfix">-->
<!--              <div class="clearfixleft">-->
<!--                兑换单号 {{item.exchangeNumber ? item.exchangeNumber : 0}}-->
<!--              </div>-->
<!--              <div class="clearfixleftright"-->
<!--                   style="color:#7AC756 ; border-color: #7AC756;background: rgba(122, 199, 86, 0.2);">-->
<!--                已领取-->
<!--              </div>-->

<!--            </div>-->
<!--            <div class="leftImg">-->
<!--              <img :src="item.image" alt="" />-->
<!--            </div>-->
<!--            <div class="rightText">-->
<!--              <div class="rightText-1">-->
<!--                {{item.honorTitle}}-->
<!--              </div>-->
<!--              <div class="rightText-3">-->
<!--                兑换数量：x1-->
<!--              </div>-->
<!--              <div class="rightText-4">-->
<!--                {{item.redeemPoints? item.redeemPoints: 0}}积分-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--          <van-empty v-show="data.mallList.length < 1"  style="position: fixed;top: 100px;left: 0;right: 0;bottom: 0;margin: auto"  description="暂无数据" />-->
<!--        </div>-->
<!--      </van-tab>-->
<!--    </van-tabs>-->
  </div>
</template>

<script>
import {onMounted, reactive, ref} from "vue";
import {pioneerMallRecordGet, pioneershopRecordGet} from "@/api/integralDetail";
import router from "@/router";
export default {
  setup() {
    const active = ref(0);
    const onClickTab = () => {
      pioneerMallRecord()
      pioneerShopRecord()
    };
    const data = reactive({
      mallList:[],
      exchangeList:[],
    })
    onMounted(()=>{
      pioneerShopRecord();
      pioneerMallRecord();
    })
    const code = (e) => {
      console.log("111111111", e.index)
      console.log("111111111", e.item.id)
      router.push({name: 'exchangeParticulars', query: {id: e.item.id,type:e.index}})
    }
    const pioneerShopRecord = () => {
      const phone = localStorage.getItem('phone');
      const type = localStorage.getItem('type');

      if(type==='0'){
        let data1 = {
          phone,
          rule:4
        }
        pioneershopRecordGet(data1).then(res=>{
          console.log("3434343",res.data.records)
          data.exchangeList=res.data.records
        })
      }else {
        let data1 = {
          phone,
          rule:2
        }
        pioneershopRecordGet(data1).then(res=>{
          console.log("3434343",res.data.records)
          data.exchangeList=res.data.records
        })
      }

    }
    const pioneerMallRecord = () => {
      pioneerMallRecordGet({getUser:"4"}).then(res=>{
        console.log("1121212112",res.data.records)
        data.mallList=res.data.records
      })
    }
    return {
      data,
      active,
      code,
      onClickTab,
    };
  },
};
</script>

<style lang="scss" scoped>
.gray-tag {
  background-color: #ccc; // 设置灰色背景
}

.IconBox {
  width: 100%;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  background-color: #fff;
  .Icon {
    display: flex;
    background: #fff;
    align-items: center;
    position: relative;
    margin-bottom: 20px;
    border-radius: 8px;
    flex-wrap: wrap;

    .leftImg {
      width: 200px;
      height: 200px;
      margin: 16px;
      border-radius: 8px;
      overflow: hidden;

      img {
        display: block;
        width: 100%;
        height: 100%;
      }
    }

    .rightText {
      //display: flex;
      flex-direction: row;

      .rightText-1 {
        color: #404040;
        font-size: 28px;
        font-weight: 600;
        margin-bottom: 14px;
        padding-top: 20px;
      }

      .rightText-3 {
        width: 319px;
        font-size: 24px;
        margin-bottom: 12px;
        margin-top: 100px;
        margin-left: 0;
      }

      .rightText-4 {
        font-size: 36px;
        margin-bottom: 12px;
        margin-top: 90px;
        margin-left: auto;
        color: #FF6637;
        font-weight: bold;
      }
    }
  }

  .IconStyle {
    background: #EAEAEA;
  }
}

.clearfix {
  width: 100%;
  height: 80px;
  border-bottom: 1px solid #f5f5f5;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 16px;
  padding-right: 30px;
  position: relative;


  .clearfixleft {
    font-size: 28px;
    color: #999;
  }

  .clearfixleftright {
    width: 58*2px;
    height: 24*2px;
    font-size: 24px;
    //color: red;
    position: absolute;
    right: 16px;
    top: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    align-items: center;
    border-radius: 3px;
    //background-color: #FFECEB;
    border: 2px solid red;
    padding-left: 20px;
  }
}
</style>
