<template>
  <div style="background: #f8f8f8;">
    <van-tabs
        v-model:active="active"
        color="#EE0A24"
        title-active-color="#EE0A24"
        @click-tab="onClickTab"
    >
      <van-tab title="待参与">
        <div style="padding-top: 20px">
          <div v-for="(item,index) in form.check_static" :key="index" class="shangPinList">
            <div class="shangPinListTop">
              <div class="shangPinListTopText">订单号:{{ item.applyCode }}</div>
              <div v-show="item.checkStatic === 1" class="shangPinListTopBotton">待审核</div>
              <div v-show="item.checkStatic === 2" class="shangPinListTopBotton">已审核</div>
              <div v-show="item.checkStatic === 3" class="shangPinListTopBotton">不通过</div>
            </div>
            <div class="shangPinListBottom">
              <div class="img">
                <img :src="item.thumbanil" alt="">
              </div>
              <div class="text">
                <div class="text1">{{ item.acvitityName }}</div>
                <div class="text2">活动时间：{{ item.acvitityStartTime }} ~ {{ item.acvitityEndTime }}</div>
                <div class="text2">兑换数量：{{ item.applyStaticNum }}</div>
                <div class="text2">消耗积分：{{ item.intrgral }}积分</div>
              </div>
            </div>
            <div class="btnBox">
              <div class="btnBoxs" @click="jump({jumpStyle:'取消报名',item:item})">取消报名</div>
              <div class="btnBoxsRed" v-if="item.checkStatic === 2" @click="code({item:item})">查看券码</div>
            </div>
          </div>
        </div>
        <van-empty v-show="form.check_static.length < 1" description="暂无数据" style="position: fixed; top: 40px; left: 0;right: 0;bottom: 100px;margin: auto"/>
      </van-tab>
      <van-tab title="已完成">
        <div style="padding-top: 20px">
          <div v-for="(item,index) in form.check_static" :key="index" class="shangPinList">
            <div class="shangPinListTop">
              <div class="shangPinListTopText">订单号:{{ item.applyCode }}</div>
              <div v-show="item.applyStatic === 2" class="shangPinListTopBotton">已完成</div>

            </div>
            <div class="shangPinListBottom">
              <div class="img">
                <img :src="item.thumbanil" alt="">
              </div>
              <div class="text">
                <div class="text1">{{ item.acvitityName }}</div>
                <div class="text2">活动时间：{{ item.acvitityStartTime }} ~ {{ item.acvitityEndTime }}</div>
                <div class="text2">兑换数量：{{ item.applyStaticNum }}</div>
                <div class="text2">消耗积分：{{ item.intrgral }}积分</div>
              </div>
            </div>
            <div class="btnBox">
<!--              <div class="btnBoxs" @click="jump({jumpStyle:'取消报名',item:item})">取消报名</div>-->
              <div class="btnBoxsRed" @click="code({item:item})">查看券码</div>
            </div>
          </div>
        </div>
        <van-empty v-show="form.check_static.length < 1" description="暂无数据" style="position: fixed; top: 40px; left: 0;right: 0;bottom: 100px;margin: auto"/>
      </van-tab>
      <van-tab title="已取消">
        <div style="padding-top: 20px">
          <div v-for="(item,index) in form.check_static" :key="index" class="shangPinList">
            <div class="shangPinListTop">
              <div class="shangPinListTopText">订单号:{{ item.applyCode }}</div>
              <div v-show="item.applyStatic === 3" class="shangPinListTopBotton">已取消</div>
            </div>
            <div class="shangPinListBottom">
              <div class="img">
                <img :src="item.thumbanil" alt="">
              </div>
              <div class="text">
                <div class="text1">{{ item.acvitityName }}</div>
                <div class="text2">活动时间：{{ item.acvitityStartTime }} ~ {{ item.acvitityEndTime }}</div>
                <div class="text2">兑换数量：{{ item.applyStaticNum }}</div>
                <div class="text2">消耗积分：{{ item.intrgral }}积分</div>
              </div>
            </div>
<!--            <div class="btnBox">
              <div class="btnBoxs" @click="jump({jumpStyle:'取消报名',item:item})">取消报名</div>
              <div class="btnBoxsRed" @click="code({item:item})">查看券码</div>
            </div>-->
          </div></div>
        <van-empty v-show="form.check_static.length < 1" description="暂无数据" style="position: fixed; top: 40px; left: 0;right: 0;bottom: 100px;margin: auto"/>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import {ref, onMounted, reactive} from "vue";
import {applyGet} from "@/api";
import router from "@/router";
import {updateStatePost} from "@/api/myActivity";
import {showConfirmDialog, showNotify} from "vant";

export default {
  setup() {
    const active = ref(0);
    const form = reactive({
      check_static: [],
      type:1
    })
    const onClickTab = ({title}) => {
      if (title === '待参与'){
        form.type = 1
      }
      if (title === '已完成'){
        form.type = 2

      }
      if (title === '已取消'){
        form.type = 3
      }
      form.check_static = []
      applyList(form.type);
    }

    onMounted(() => {
      applyList(1);


    })
    const applyList = (applyStatic) => {
      const type = localStorage.getItem('type');
      let userType;
      if(type==='0'){
        userType = 4
      }else{
        userType = 5
      }
      let params ={
        applyStatic:applyStatic,
        userType:userType,
        phone: localStorage.getItem('phone'),
      }
      applyGet(params).then(res => {
        console.log(res)

        let records = res.data.page.records
        records.forEach(item => {
          item.acvitityStartTime = item.acvitityStartTime.substring(0, 10)
          item.acvitityEndTime = item.acvitityEndTime.substring(0, 10)
        });
        form.check_static = records
        console.log(form.check_static)
      })
    }
    const code = (e) => {
      console.log("111111111", e.item.id)
      router.push({name: 'checkOffCode', query: {id: e.item.id}})
    }
    const jump = (e)=>{
      console.log(e)
      if (e.jumpStyle === "取消报名"){

        showConfirmDialog({
          title: '温馨提示',
          message:
              '确认取消报名吗？',
        }).then(() => {
          let params = [e.item.id]
          let params2 = localStorage.getItem('phone')
          updateStatePost(params,params2).then(res=>{
            console.log(res)
            showNotify({ type: 'success', message: res.msg });
            // 活动列表接口
            applyList(1)
          })
        }).catch(() => {
          // on cancel
        });
      }
    }
    return {
      active,
      form,
      jump,
      onClickTab,
      applyList,
      code,
    };
  },
};
</script>

<style lang="scss" scoped>
.shangPinList {
  border-bottom: 1px solid #f5f5f5;
  background: #fff;

  .shangPinListTop {
    width: 100%;
    padding-left: 14 * 2px;
    padding-right: 14 * 2px;
    padding-top: 11 * 2px;
    padding-bottom: 11 * 2px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .shangPinListTopText {
    color: #999;
    font-size: 14 * 2px;
  }

  .shangPinListTopBotton {
    // background: #ffeceb;
    // color: #ff463b;
    font-size: 14 * 2px;
    padding: 6px 20px;
    // border: 1px solid #ff463b;
    border-radius: 6px;
  }

  .shangPinListTopBotton2 {
    background: #f1f9ee;
    color: #7ac756;
    font-size: 14 * 2px;
    padding: 6px 20px;
    border: 1px solid #7ac756;
    border-radius: 6px;
  }

  .shangPinListBottom {
    padding-left: 17 * 2px;
    padding-right: 17 * 2px;
    padding-bottom: 10 * 2px;
    display: flex;
    position: relative;
    border-bottom: 1px solid #f5f5f5;

    .img {
      //width: 200px;
      //height: 200px;
      //background: red;
      //margin-right: 18 * 2px;
      //border-radius: 8px;

      width: 110 * 2px;
      border-radius: 4 * 2px;
      overflow: hidden;
      margin-right: 8*2px;
    }

    .text {
      width: 440px;

      .text1 {
        //margin-bottom: 10px;
        //font-size: 14 * 2px;
        //font-weight: bold;

        width: 6.3rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: .4rem;
        font-weight: 700;
        color: #404040;
        margin-bottom: .16rem;
      }

      .text2 {
        //font-size: 12 * 2px;
        //color: #999;
        margin-bottom: 8*2px;
        font-size: .3rem;
        font-weight: 400;
        color: #101010;
      }

      // .text3 {
      //   position: absolute;
      //   right: 30px;
      //   top: 60px;
      // }
    }
  }

  .btnBox {
    display: flex;
    justify-content: flex-end;
    width: 750px;
    padding-right: 20px;
    align-items: center;
    border-bottom: 22px solid #f5f5f5;
    padding-top: 20px;
    padding-bottom: 20px;

    .btnBoxs {
      margin-left: 15*2px;
      padding: 10px 20px;
      border: 1px solid #999;
      border-radius: 100px;
      font-size: 12*2px;
      color: #999;
    }

    .btnBoxsRed {
      margin-left: 15*2px;
      padding: 10px 20px;
      border: 1px solid transparent;
      background: #EE0A24;
      border-radius: 100px;
      font-size: 12*2px;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.shangPinList:last-child {
  border: none;
}

.shangPinList:last-child > .btnBox {
  border: none;
}
</style>
