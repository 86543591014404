<template>
  <div>
    <div class="box">
      <div class="topBox">
        <div class="top">
          <div class="topText">
            <div class="topText1">累计积分</div>
            <div class="topText2">{{ data.totalIntegral ? data.totalIntegral : 0 }}</div>
          </div>
          <div class="topText">
            <div class="topText1">可用积分</div>
            <div class="topText2">{{ data.availableIntegral? data.availableIntegral : 0  }}</div>
          </div>
        </div>
        <div class="bottom">
          <div class="text">{{data.lv}}
          </div>
          <div class="bttomJinDuTiao">
            <van-progress
                :percentage="data.bfb"
                color="linear-gradient(to right, #FF6666, #FF0000)"
                pivot-color="#7232dd"
                pivot-text=""
            />
          </div>
          <div class="text">
            {{data.lv2}}
          </div>
        </div>
      </div>
      <div class="caiDanBox">
        <div class="caiDan" @click="jump({jumpStyle: '积分明细'})">
          <div class="img">
            <img alt="" src="@/assets/image/zd.png">
          </div>
          <div class="text">
            积分明细
          </div>
        </div>
        <div class="caiDan" @click="jump({jumpStyle: '兑换记录'})">
          <div class="img">
            <img alt="" src="@/assets/image/lw.png">
          </div>
          <div class="text">
            兑换记录
          </div>
        </div>
      </div>
      <div class="jiFenShangChengTiaoTi">
        <div class="leftBackground"></div>
        <div class="leftText">积分商城</div>
      </div>
      <div class="shangPinListBox">
        <div class="shangPinList" v-for="(item,index) in data.shopGet" :key="index" @click="code({item:item})">
          <div class="img"><img :src="item.goodsImage" alt=""/></div>
          <div class="text">{{item.goodsName}}</div>
          <div class="jiFenBox">
            <div class="left"><span>{{item.exchangeIntegral}}</span> 积分</div>
            <div class="right">数量：{{item.goodsResidue}}</div>
          </div>
          <div class="bottom" v-if="item.exchangeIntegral <= data.availableIntegral">
            立即兑换
          </div>
          <div class="bottom" style="background-color: #F4C6CB" v-else-if="item.exchangeIntegral > data.availableIntegral">积分不足</div>
        </div>

<!--        <div class="shangPinList">-->
<!--          <div class="img"></div>-->
<!--          <div class="text">充电宝充电宝充电宝...</div>-->
<!--          <div class="jiFenBox">-->
<!--            <div class="left"><span>100</span> 积分</div>-->
<!--            <div class="right">数量：100</div>-->
<!--          </div>-->
<!--          <div class="bottom">-->
<!--            立即兑换-->
<!--          </div>-->
<!--        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
import {onMounted, reactive, ref} from "vue";
import {pioneerDetailsGet, pioneerGet} from "@/api/myIntegral";
import router from "@/router";
import {pioneerShopGet} from "@/api/integralDetail";
import {showToast} from "vant";

export default {
  setup() {

    const data = reactive({
      availableIntegral: null,
      rules:null,
      rulesAttr:[],
      lv:'',
      bfb:null,
      totalIntegral:null,

    })

    onMounted(() => {
      integralInfo();
      pioneerDetails();
      pioneerShop();
    })

    const pioneerDetails = () =>{
      pioneerDetailsGet().then(res=>{
        console.log(res)
      })
    }

    const pioneerShop = () =>{
      const type = localStorage.getItem('type');
      if(type==='1'){
        pioneerShopGet({ exchangeObjectValue: '2' }).then(res=>{
          data.shopGet =  res.data.records
        })
      }else{
        pioneerShopGet({ exchangeObjectValue: '3' }).then(res=>{
          data.shopGet =  res.data.records
        })
      }
    }

    const integralInfo = () => {
      pioneerGet().then(res => {
        if (res.code === 200){
          data.totalIntegral = res.data.statistics.totalIntegral ?  res.data.statistics.totalIntegral : 0
          data.availableIntegral = res.data.statistics.availableIntegral ?  res.data.statistics.availableIntegral : 0
          data.aaaaa = res.data.grades

          if (data.totalIntegral >= res.data.grades[0].lowIntegral && data.totalIntegral <= res.data.grades[0].highIntegral){
            data.lv='LV1'; data.lv2='LV2';data.bfb= Math.ceil(data.availableIntegral/(res.data.grades[0].highIntegral/100))
          }
          if (data.totalIntegral >= res.data.grades[1].lowIntegral && data.totalIntegral <= res.data.grades[1].highIntegral){
            data.lv='LV2';data.lv2='LV3';data.bfb= Math.ceil(data.availableIntegral/(res.data.grades[1].highIntegral/100))
          }
          if (data.totalIntegral >= res.data.grades[2].lowIntegral && data.totalIntegral <= res.data.grades[2].highIntegral){
            data.lv='LV3';data.lv2='LV4';data.bfb= Math.ceil(data.availableIntegral/(res.data.grades[2].highIntegral/100))
          }
          if (data.totalIntegral >= res.data.grades[3].lowIntegral && data.totalIntegral <= res.data.grades[3].highIntegral){
            data.lv='LV4';data.lv2='LV5';data.bfb= Math.ceil(data.availableIntegral/(res.data.grades[3].highIntegral/100))
          }
          if (data.totalIntegral >= res.data.grades[4].lowIntegral && data.totalIntegral <= res.data.grades[4].highIntegral){
            data.lv='LV5';data.lv2='MAX';data.bfb= Math.ceil(data.availableIntegral/(res.data.grades[4].highIntegral/100))
          }
        }

      })
    }

    const jump = (e) => {
      if (e.jumpStyle === '积分明细'){
        // productDetails
        router.push({name: 'integralDetail'});
      }
      if(e.jumpStyle === '兑换记录'){
        router.push({name: 'exchangeRecord'});
      }
    }
    const code = (e) => {
      console.log("aaaa", e.item.id)
      router.push({name: 'productDetails', query: {id: e.item.id}})
    }

    return {
      data,
      jump,
      code,
      integralInfo,
      pioneerDetails,
    }
  }
}
</script>

<style lang="scss" scoped>
.box {
  .topBox {
    width: 750px;
    height: 198*2px;
    background: #FF8585;
    padding-top: 40px;
    margin-bottom: 40px;

    .top {
      display: flex;
      justify-content: space-around;
      align-items: center;
      height: 170px;
      margin-bottom: 20px;

      .topText {
        color: #fff;

        .topText1 {
          font-size: 14*2px;
          margin-bottom: 10px;
        }

        .topText2 {
          font-size: 28*2px;
        }
      }
    }

    .bottom {
      color: #fff;
      display: flex;
      width: 575px;
      margin: 0 auto;
      align-items: center;

      .bttomJinDuTiao {
        width: 197*2px;
        margin-left: 13*2px;
        margin-right: 13*2px;
      }
    }

  }

  .caiDanBox {
    display: flex;
    margin-left: 80*2px;
    margin-bottom: 23*2px;

    .caiDan {
      text-align: center;
      margin-right: 90*2px;

      .img {
        width: 60px;
        height: 60px;
        margin: auto;
        margin-bottom: 8px;
      }
    }
  }

  .jiFenShangChengTiaoTi {
    display: flex;
    align-items: center;
    margin-bottom: 22*2px;
    position: sticky;
    top: 0;
    z-index: 1000;
    background: #fff;
    padding-top: 10px;
    padding-bottom: 10px;

    .leftBackground {
      width: 6px;
      height: 40px;
      background: red;
      margin-left: 26px;
      margin-right: 16px;
    }

    .leftText {
      font-size: 16*2px;
      color: #333;
    }
  }

  .shangPinListBox {
    width: 750px;
    padding-left: 22px;
    padding-right: 22px;
    display: flex;
    flex-wrap: wrap;

    .shangPinList {
      width: 171*2px;
      height: 220*2px;
      background-color: rgba(255, 255, 255, 1);
      color: rgba(16, 16, 16, 1);
      box-shadow: 1px 6px 12px 0px rgba(187, 187, 187, 20);
      border-radius: 8px;
      padding: 20px;
      margin-bottom: 20px;

      .img {
        width: 148*2px;
        height: 99*2px;
        border-radius: 4*2px;
        overflow: hidden;
        margin-bottom: 20px;
      }

      .text {
        font-size: 14*2px;
        color: #333;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 8*2px;
      }

      .jiFenBox {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10*2px;

        .left {
          color: #ff6637;
          font-size: 14*2px;

          span {
            font-weight: bold;
            font-size: 18*2px;
          }
        }

        .right {
          color: #999;
          font-size: 12*2px;
        }
      }

      .bottom {
        width: 143*2px;
        height: 30*2px;
        background: #ee0a24;
        border-radius: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        font-size: 14*2px;
      }

      .bottom2 {
        width: 143*2px;
        height: 30*2px;
        background: #FCCED3;
        border-radius: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        font-size: 14*2px;
      }
    }

    .shangPinList:nth-child(2n) {
      margin-left: 20px;
    }
  }
}
</style>
